import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

export const InfosContext = React.createContext<unknown>({});

// This will be used in case we state shared inside the module
export const InfoProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <InfosContext.Provider value={providerValue}>{children}</InfosContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
