import { Button, Container, Header, Divider } from 'components';
import { useAuth } from 'GlobalProvider';
import { useProfilePage } from './ProfilePageProvider';
import { PersonalInformation } from './components';

export const ProfilePage = () => {
  const { onSubmit } = useProfilePage();
  const { logoutUser } = useAuth();

  return (
    <>
      <Header title={'Edit profile'}>
        <Button className="mr-4" onClick={logoutUser} label="Sign Out" />

        <Button type="submit" onClick={onSubmit} label="Save" form="profileForm" />
      </Header>

      <Container>
        <div className="bg-white overflow-hidden shadow rounded-lg px-12 py-10">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="profileForm">
              <Divider title="Personal information" />
              <PersonalInformation />
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
