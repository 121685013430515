import { DatePicker, Divider, FormItem, ImageUpload, Input } from 'components';
import { Select } from 'components/Select';
import { useSignUpPage } from 'modules/SignUp/SignUpProvider';
import { ExpectedAvailability } from 'services';

export const SignUpStep3 = () => {
  const { formState, register, control } = useSignUpPage();

  return (
    <>
      <Divider title="Driver and Vehicle Info" titlePosition="center" />

      <>
        <FormItem
          label="Driver's License Number"
          htmlFor="driversLicenseNumber"
          error={formState.errors.driversLicenseNumber?.message}
          required
        >
          <Input
            {...register('driversLicenseNumber')}
            id="driversLicenseNumber"
            type="text"
            required
            placeholder="Driver's License Number"
            error={!!formState.errors.driversLicenseNumber?.message}
          />
        </FormItem>

        <FormItem
          label="State"
          htmlFor="driversLicenseState"
          error={formState.errors.driversLicenseState?.message}
          required
        >
          <Input
            {...register('driversLicenseState')}
            id="Vehicle State"
            type="text"
            placeholder="Vehicle State"
            required
            error={!!formState.errors.driversLicenseState?.message}
          />
        </FormItem>

        <FormItem
          label="Exp Date"
          htmlFor="driversLicenseExpirationDate"
          error={formState.errors.driversLicenseExpirationDate?.message}
          required
        >
          <DatePicker
            control={control}
            name="driversLicenseExpirationDate"
            error={!!formState.errors.driversLicenseExpirationDate?.message}
          />
        </FormItem>

        <FormItem label="Vehicle Color" htmlFor="vehilceColor" error={formState.errors.vehicleColor?.message} required>
          <Input
            {...register('vehicleColor')}
            id="vehicleColor"
            type="text"
            required
            placeholder="Vehicle Color"
            error={!!formState.errors.vehicleColor?.message}
          />
        </FormItem>

        <FormItem label="Vehicle Make" htmlFor="vehicleMake" error={formState.errors.vehicleMake?.message} required>
          <Input
            {...register('vehicleMake')}
            id="vehicleMake"
            type="text"
            required
            placeholder="Vehicle Make"
            error={!!formState.errors.vehicleMake?.message}
          />
        </FormItem>

        <FormItem label="Vehicle Model" htmlFor="vehicleModel" error={formState.errors.vehicleModel?.message} required>
          <Input
            {...register('vehicleModel')}
            id="vehicleModel"
            type="text"
            required
            placeholder="Vehicle Model"
            error={!!formState.errors.vehicleModel?.message}
          />
        </FormItem>

        <FormItem
          label="Vehicle Year"
          htmlFor="vehicleYear"
          error={formState.errors.vehicleYear?.message}
          required
          help="Vehicles older than 2005 will need a special exception"
        >
          <Input
            {...register('vehicleYear')}
            id="vehicleYear"
            type="text"
            required
            placeholder="Vehicle Year"
            error={!!formState.errors.vehicleYear?.message}
          />
        </FormItem>

        <FormItem
          label="How far are you willing to drive?"
          htmlFor="maximumDrivingDistanceMiles"
          error={formState.errors.maximumDrivingDistanceMiles?.message}
          required
        >
          <Input
            {...register('maximumDrivingDistanceMiles')}
            id="maximumDrivingDistanceMiles"
            type="text"
            required
            placeholder="Miles"
            error={!!formState.errors.maximumDrivingDistanceMiles?.message}
          />
        </FormItem>

        <FormItem
          label="Expected Availability"
          htmlFor="expectedAvailabilityId"
          error={formState.errors.expectedAvailabilityId?.message}
          required
        >
          <Select
            control={control}
            name="expectedAvailabilityId"
            options={Object.values(ExpectedAvailability)}
            error={!!formState.errors.expectedAvailabilityId?.message}
          />
        </FormItem>

        <ImageUpload
          control={control}
          name="driversLicenseFile"
          label="Driver's License"
          required
          error={formState.errors.driversLicenseFile?.url?.message}
        />
      </>
    </>
  );
};
