import { postImage } from './api';
import { imageToFormData } from './transformations';
import { Image } from './types';

export const uploadImages = async (images: { [name: string]: Image }): Promise<{ [name: string]: Image }> => {
  const newImages = { ...images };

  await Promise.all(
    Object.keys(images).map(async (name) => {
      if (images[name].buffer.byteLength > 0) {
        newImages[name] = await postImage(imageToFormData(images[name]));
      }
    }),
  );

  return newImages;
};
