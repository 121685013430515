import { Divider, FormItem, Input, Checkbox } from 'components';
import { useSignUpPage } from 'modules/SignUp/SignUpProvider';

export const SignUpStep2 = () => {
  const { formState, register } = useSignUpPage();

  return (
    <>
      <Divider title="Basic Information" titlePosition="center" />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <FormItem label="Address 1" htmlFor="address1" error={formState.errors.address1?.message} required>
          <Input
            {...register('address1')}
            id="address1"
            type="text"
            required
            placeholder="Street Address"
            error={!!formState.errors.address1?.message}
          />
        </FormItem>

        <FormItem label="Address 2" htmlFor="address2" error={formState.errors.address2?.message}>
          <Input
            {...register('address2')}
            id="address2"
            type="text"
            placeholder="Apt, Suite, etc."
            error={!!formState.errors.address2?.message}
          />
        </FormItem>

        <FormItem label="City" htmlFor="city" error={formState.errors.city?.message} required>
          <Input
            {...register('city')}
            id="city"
            type="city"
            required
            placeholder="City"
            error={!!formState.errors.city?.message}
          />
        </FormItem>

        <FormItem label="State" htmlFor="state" error={formState.errors.state?.message} required>
          <Input
            {...register('state')}
            id="state"
            type="text"
            required
            placeholder="State"
            error={!!formState.errors.state?.message}
          />
        </FormItem>

        <FormItem label="Zip Code" htmlFor="zip" error={formState.errors.zip?.message} required>
          <Input
            {...register('zip')}
            id="zip"
            type="zip"
            required
            placeholder="Zip Code"
            error={!!formState.errors.zip?.message}
          />
        </FormItem>

        <div className="flex items-center justify-center h-full">
          <FormItem>
            <Checkbox {...register('isHelper')} label="Is Helper?" id="isHelper" />
          </FormItem>
        </div>
      </div>
    </>
  );
};
