import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

export const ProfilesContext = React.createContext<unknown>({});

// This will be used in case we state shared inside the module
export const ProfileProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <ProfilesContext.Provider value={providerValue}>{children}</ProfilesContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
