import { api } from '../api';

import { resToUser } from '../users';
import { resToAuthData, userToPutAuthUser } from './transformations';
import { AuthData, LoginRequest } from './types';

export const login = async (data: LoginRequest): Promise<AuthData> => {
  const resp = await api.post('/users/auth/authenticate', data);

  const authUser = resToAuthData(resp.data);

  if (!authUser.user?.isDriver) {
    throw new Error('User is not a driver');
  }

  return resToAuthData(resp.data);
};

export const updateAuthUser = async (data: any) => {
  const resp = await api.put('/users/me', userToPutAuthUser(data));
  return resToUser(resp.data);
};
