import * as yup from 'yup';

export const PROFILE_VALIDATION_SCHEMA = yup.object({
  email: yup.string().required('E-mail is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  dateOfBirth: yup.date().typeError('Date of birth is required'),
  address1: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup.string().required('Zip is required'),
  // profilesLicenseExpirationDate: yup.date().typeError('Profiles license expiration date is required'),
  // profilesLicenseNumber: yup.string().required('Profiles license number is required'),
  // profilesLicenseState: yup.string().required('Profiles license state is required'),
  // vehicleMake: yup.string().required('Vehicle make is required'),
  // vehicleModel: yup.string().required('Vehicle model is required'),
  // vehicleYear: yup
  //   .number()
  //   .typeError('Vehicle year is required')
  //   .test('len', 'Vehicle year must have 4 digits', (val: any) => val.toString().length === 4),
  // vehicleColor: yup.string().required('Vehicle color is required'),
  // // TODO: Add validation for vehicle type once it is added to the database
  // // vehicleType: yup.string().required('Vehicle type is required'),
  // maximumDrivingDistanceMiles: yup.number().typeError('Maximum driving distance is required'),
  // orderPaymentPercentage: yup
  //   .number()
  //   .typeError('Order payment percentage is required')
  //   .min(0, 'Order payment percentage must betweeen 0 and 100')
  //   .max(100, 'Order payment percentage must betweeen 0 and 100'),
  // firstHelperPaymentPercentage: yup
  //   .number()
  //   .typeError('Order payment percentage is required')
  //   .min(0, 'Order payment percentage must betweeen 0 and 100')
  //   .max(100, 'Order payment percentage must betweeen 0 and 100'),
  // secondHelperPaymentPercentage: yup
  //   .number()
  //   .typeError('Order payment percentage is required')
  //   .min(0, 'Order payment percentage must betweeen 0 and 100')
  //   .max(100, 'Order payment percentage must betweeen 0 and 100'),
  // vehicleInsuranceFile: yup.object({
  //   url: yup.string().required('Vehicle insurance file is required'),
  // }),
  // vehicleRegistrationFile: yup.object({
  //   url: yup.string().required('Vehicle registration file is required'),
  // }),
  // profilesLicenseFile: yup.object({
  //   url: yup.string().required('Profiles license file is required'),
  // }),
});
