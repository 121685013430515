import { Control, useController } from 'react-hook-form';
import clsx from 'clsx';

import { fileToImage, initialImage } from 'services';

export const ImageUpload = ({ name, label, control, className, required = false, error, help }: Props) => {
  const { field } = useController({ control, name });
  const innerOnChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    const files = ev.target?.files;
    if (files) {
      const file = files[0];
      const image = await fileToImage(file);
      field.onChange(image);
    }
  };

  const removeClicked = (ev: any) => {
    ev.preventDefault();
    field.onChange(initialImage);
  };

  return (
    <div className={className}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label} {required ? <span className="text-red-500">*</span> : null}
        </label>
      )}
      <div className="flex flex-col flex-1">
        <div className="flex justify-center items-center p-2 border-2 border-gray-300 border-dashed rounded-md flex-1">
          {field.value?.url && <img src={field.value?.url} className="object-cover h-100 max-h-40" alt="" />}
          {!field.value?.url && (
            <div className="space-y-1 text-center flex flex-col justify-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="text-sm text-gray-600 text-center">
                <label
                  htmlFor={`file-upload-${name}`}
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span>Upload a file</span>
                  <input id={`file-upload-${name}`} onChange={innerOnChange} type="file" className="sr-only" />
                </label>
              </div>
              <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
            </div>
          )}
        </div>
        {field.value?.url && (
          <div className="flex flex-column justify-end">
            <button className="" type="button" onClick={removeClicked}>
              Remove
            </button>
          </div>
        )}
        {error || help ? (
          <div className={clsx('mt-2 text-sm', !error ? 'text-gray-500' : 'text-red-500')}>{error || help}</div>
        ) : null}
      </div>
    </div>
  );
};

interface Props {
  name: string;
  control: Control<any, any>;
  className?: string;
  label?: string;
  required?: boolean;
  error?: string;
  help?: string;
}
