import { Divider, ImageUpload } from 'components';
import { useSignUpPage } from 'modules/SignUp/SignUpProvider';

export const SignUpStep5 = () => {
  const { formState, control } = useSignUpPage();

  return (
    <>
      <Divider title="Upload Documents" titlePosition="center" />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <ImageUpload
          control={control}
          name="vehicleInsuranceFile"
          label="Take a photo of your vehicle insurance"
          help="Make sure your name, VIN, insurance company, and expiration date are clear and visible."
          required
          error={formState.errors.vehicleInsuranceFile?.url?.message}
        />

        <ImageUpload
          control={control}
          name="vehicleRegistrationFile"
          label="Take a photo of your vehicle registration"
          help="Make sure your vehicle make, model, year, license plate, VIN and expiration date are clear and visible."
          required
          error={formState.errors.vehicleRegistrationFile?.url?.message}
        />
      </div>
    </>
  );
};
