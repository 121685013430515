import { PageType } from 'helpers';
import { Image, StorageType } from './types';

export const resToImage = (res: any): Image => {
  return {
    id: res.id,
    name: res.name ?? '',
    url: `${process.env.REACT_APP_API_URL}/storage/${res.id}`,
    buffer: new ArrayBuffer(0),
  };
};

export const imageToFormData = (image: Image): FormData => {
  const formData = new FormData();
  formData.append('file', new File([image.buffer], image.name, { type: 'image/*' }));
  formData.append('storageTypeId', StorageType.Assets.toString());
  return formData;
};

export const fileToFormData = async (file: File): Promise<FormData> => {
  const image: Image = {
    id: PageType.Create,
    name: file.name,
    url: URL.createObjectURL(file),
    buffer: await file.arrayBuffer(),
  };
  return imageToFormData(image);
};

export const fileToImage = async (file: File): Promise<Image> => {
  return {
    id: PageType.Create,
    name: file.name,
    url: URL.createObjectURL(file),
    buffer: await file.arrayBuffer(),
  };
};

export const idAndUrlToImage = (id: string, url: string): Image => {
  return {
    id: id || PageType.Create,
    name: '',
    url,
    buffer: new ArrayBuffer(0),
  };
};
