import { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage } from 'helpers';
import { defaultProfileForm, fetchProfile, ProfileForm, profileToProfileForm, putProfile } from 'services';

import { PROFILE_VALIDATION_SCHEMA } from './const';

const ProfilePageContext = createContext<{
  formState: FormState<ProfileForm>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<ProfileForm>;
  control: Control<ProfileForm, any>;
  profileForm: ProfileForm | null;
}>({} as any);

// This will be used in case we state shared inside the module
export const ProfilePageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: profile } = useQuery(['profile'], () => fetchProfile());
  const { mutateAsync: updateProfile } = useMutation(putProfile);

  const { handleSubmit, register, formState, reset, control } = useForm<ProfileForm>({
    defaultValues: defaultProfileForm,
    resolver: yupResolver(PROFILE_VALIDATION_SCHEMA),
  });

  const profileForm = useMemo(() => {
    if (profile) {
      return profileToProfileForm(profile);
    }
    return defaultProfileForm;
  }, [profile]);

  useEffect(() => {
    reset(profileForm);
  }, [profileForm]);

  const onSubmit = handleSubmit(async (values: ProfileForm) => {
    try {
      await updateProfile(values);
      showMessage('Profile successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('profiles');
      navigate('/profile');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, onSubmit, register, control, profileForm }),
    [formState, onSubmit, register, control, profileForm],
  );

  return <ProfilePageContext.Provider value={providerValue}>{children}</ProfilePageContext.Provider>;
};

export const useProfilePage = () => {
  return useContext(ProfilePageContext);
};

interface Props {
  children?: React.ReactNode;
}
