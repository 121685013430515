import { FlagIcon, HomeIcon, IdentificationIcon, BriefcaseIcon } from '@heroicons/react/outline';

import { NavigationItem } from '../MenuItem';

export const resolvedNavigation = (isHelper: boolean): NavigationItem[] => {
  const navigationItems: NavigationItem[] = [
    {
      to: '/',
      name: 'Dashboard',
      icon: HomeIcon,
      hidden: false,
    },
    // {
    //   to: '/deliveries',
    //   name: 'My Deliveries',
    //   icon: TruckIcon,
    //   hidden: false,
    // },
    {
      to: '/info',
      name: 'Driver and Vehicle Info',
      icon: IdentificationIcon,
      hidden: isHelper,
    },
    {
      to: '/orders',
      name: 'Orders',
      icon: FlagIcon,
      hidden: false,
    },
    {
      to: '/delivery-type',
      name: 'Delivery Type',
      icon: BriefcaseIcon,
      hidden: false,
    },
    // {
    //   to: '/reviews',
    //   name: 'Customer Reviews',
    //   icon: StarIcon,
    //   hidden: false,

    // },
    // {
    //   to: '/favorited',
    //   name: 'Favorited By Users',
    //   icon: HeartIcon,
    //   hidden: false,
    // },
  ];

  return navigationItems.filter((i) => !i.hidden);
};
