import clsx from 'clsx';

import { Divider } from 'components';
import { useSignUpPage } from 'modules/SignUp/SignUpProvider';

export const SignUpStep4 = () => {
  const { driverGroups, formState, setValue, watch } = useSignUpPage();

  const selectedGroupId = watch('driverGroupId');

  const handleGroupClick = (id: string) => () => {
    setValue('driverGroupId', id);
  };

  return (
    <>
      <Divider title="Delivery Type" titlePosition="center" />

      <div className="flex items-center justify-center">
        <p className="text-sm text-gray-600">
          Please choose how would you like to partner with
          <a href="https://ponyex.com" target="_blank" rel="noreferrer" className="text-red-600">
            {' '}
            PonyEx
          </a>
          . What is the largest delivery type your vehicle can carry?
        </p>
      </div>

      {driverGroups
        .filter((driverGroup) => driverGroup.showInApp)
        .map(({ id, name, description }) => (
          <div
            className={clsx(`flex flex-col rounded-lg overflow-hidden border lg:mx-20 p-4 cursor-pointer`, {
              'border-red-500': selectedGroupId === id,
            })}
            key={id}
            onClick={handleGroupClick(id)}
          >
            <p className="text-lg">{name}</p>
            <p className="text-sm">{description}</p>
          </div>
        ))}

      {!!formState.errors.driverGroupId?.message && (
        <div className="flex mt-10 lg:ml-20">
          <p className="text-sm text-red-600">{formState.errors.driverGroupId.message}</p>
        </div>
      )}
    </>
  );
};
