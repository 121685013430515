import { api, imageToFormData, postImage, Image } from 'services';

import { resToProfile, profileToPutReq } from './transformations';
import { Profile, ProfileForm } from './types';

const uploadProfileImages = async (images: { [name: string]: Image }): Promise<{ [name: string]: Image }> => {
  const newImages = { ...images };

  await Promise.all(
    Object.keys(images).map(async (name) => {
      if (images[name].buffer.byteLength > 0) {
        newImages[name] = await postImage(imageToFormData(images[name]));
      }
    }),
  );

  return newImages;
};

export const fetchProfile = async (): Promise<Profile> => {
  const resp = await api.get(`/drivers/me`);
  return resToProfile(resp.data);
};

export const putProfile = async (data: ProfileForm): Promise<Profile> => {
  const images = await uploadProfileImages({
    driversLicenseFile: data.driversLicenseFile,
    profilePictureFile: data.profilePictureFile,
    vehicleInsuranceFile: data.vehicleInsuranceFile,
    vehicleRegistrationFile: data.vehicleRegistrationFile,
  });

  const resp = await api.put(`/drivers/me`, profileToPutReq({ ...data, ...images }));
  return resToProfile(resp.data);
};
