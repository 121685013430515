import { Button, Container, Header, Divider } from 'components';
import { Images, VehicleInformation } from './components';

import { useInfoPage } from './InfoPageProvider';

export const InfoPage = () => {
  const { onSubmit } = useInfoPage();

  return (
    <>
      <Header title={'Edit info'}>
        <Button type="submit" onClick={onSubmit} label="Save" form="infoForm" />
      </Header>

      <Container>
        <div className="bg-white overflow-hidden shadow rounded-lg px-12 py-10">
          <div className="px-4 py-5 sm:p-6">
            <form className="space-y-10" onSubmit={onSubmit} noValidate id="infoForm">
              <Divider title="Driver and Vehicle Info" />
              <VehicleInformation />

              <Divider title="Images" />
              <Images />
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};
