import { SignUpForm } from 'modules/SignUp/types';
import { resToAddress } from '../addresses';
import { User } from './types';

export const resToUser = (res: any): User => {
  return {
    id: res.id,
    dateOfBirth: res.dateOfBirth ?? new Date(),
    email: res.email ?? '',
    firstName: res.firstName ?? '',
    homeAddress: res.homeAddress ? resToAddress(res.homeAddress) : null,
    isAdmin: res.isAdmin ?? false,
    isCustomer: res.isCustomer ?? false,
    isDriver: res.isDriver ?? false,
    isHelper: res.isHelper ?? false,
    isMerchant: res.isMerchant ?? false,
    lastName: res.lastName ?? '',
    phoneNumber: res.phoneNumber ?? '',
    workAddress: res.workAddress ? resToAddress(res.workAddress) : null,
  };
};

export const userToPutReq = (data: any) => {
  const mustData = {
    firstName: data.firstName,
    lastName: data.lastName,
  };

  if (data.password) {
    return {
      ...mustData,
      password: data.password,
    };
  }

  return mustData;
};

export const userToPostReq = (data: any) => {
  return {
    email: data.email,
    password: data.password,
    firstName: data.firstName,
    lastName: data.lastName,
  };
};

export const signUpFormToPostReq = (form: SignUpForm) => {
  let payload: any = {
    user: {
      email: form.email,
      password: form.password,
      firstName: form.firstName,
      lastName: form.lastName,
      isDriver: true,
      isCustomer: false,
      isAdmin: false,
      isMerchant: false,
      phoneNumber: form.phoneNumber,
      dateOfBirth: form.dateOfBirth?.toISOString() ?? null,
      homeAddress: {
        street1: form.address1,
        street2: form.address2,
        city: form.city,
        state: form.state,
        zip: form.zip,
        country: 'US',
        lat: 0,
        lng: 0,
      },
    },
    isHelper: form.isHelper,
    driverGroupIds: [form.driverGroupId],
  };

  if (!form.isHelper) {
    payload = {
      ...payload,
      driversLicenseNumber: form.driversLicenseNumber,
      driversLicenseState: form.driversLicenseState,
      driversLicenseExpirationDate: form.dateOfBirth?.toISOString() ?? null,
      vehicleMake: form.vehicleMake,
      vehicleModel: form.vehicleModel,
      vehicleYear: form.vehicleYear,
      vehicleColor: form.vehicleColor,
      driverGroupIds: [form.driverGroupId],
      maximumDrivingDistanceMiles: form.maximumDrivingDistanceMiles,
      expectedAvailabilityId: form.expectedAvailabilityId,
      profilePictureFileId: form.profilePictureFile.id || null,
      driversLicenseFileId: form.driversLicenseFile.id,
      vehicleInsuranceFileId: form.vehicleInsuranceFile.id,
      vehicleRegistrationFileId: form.vehicleRegistrationFile.id,
    };
  }

  return payload;
};
