import axios from 'axios';

import { LocalStorage } from 'helpers';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  timeout: 10000,
});

api.defaults.headers.common.Authorization = `Bearer ${LocalStorage.getToken()}`;

export const unauthorizedHandler = (logoutFc: () => void) => (error: any) => {
  if (error.response !== undefined && error.response.status === 401) {
    logoutFc();
  }

  return Promise.reject(error);
};
