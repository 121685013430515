import * as yup from 'yup';

export const INFO_VALIDATION_SCHEMA = yup.object({
  driversLicenseExpirationDate: yup.date().typeError('Drivers license expiration date is required'),
  driversLicenseNumber: yup.string().required('Drivers license number is required'),
  driversLicenseState: yup.string().required('Drivers license state is required'),
  vehicleMake: yup.string().required('Vehicle make is required'),
  vehicleModel: yup.string().required('Vehicle model is required'),
  vehicleYear: yup
    .number()
    .typeError('Vehicle year is required')
    .test('len', 'Vehicle year must have 4 digits', (val: any) => val.toString().length === 4),
  vehicleColor: yup.string().required('Vehicle color is required'),
  // TODO: Add validation for vehicle type once it is added to the database
  // vehicleType: yup.string().required('Vehicle type is required'),
  maximumDrivingDistanceMiles: yup.number().typeError('Maximum driving distance is required'),
  orderPaymentPercentage: yup
    .number()
    .typeError('Order payment percentage is required')
    .min(0, 'Order payment percentage must betweeen 0 and 100')
    .max(100, 'Order payment percentage must betweeen 0 and 100'),
  firstHelperPaymentPercentage: yup
    .number()
    .typeError('Order payment percentage is required')
    .min(0, 'Order payment percentage must betweeen 0 and 100')
    .max(100, 'Order payment percentage must betweeen 0 and 100'),
  secondHelperPaymentPercentage: yup
    .number()
    .typeError('Order payment percentage is required')
    .min(0, 'Order payment percentage must betweeen 0 and 100')
    .max(100, 'Order payment percentage must betweeen 0 and 100'),
  vehicleInsuranceFile: yup.object({
    url: yup.string().required('Vehicle insurance file is required'),
  }),
  vehicleRegistrationFile: yup.object({
    url: yup.string().required('Vehicle registration file is required'),
  }),
  driversLicenseFile: yup.object({
    url: yup.string().required('Drivers license file is required'),
  }),
  expectedAvailabilityId: yup
    .string()
    .typeError('Expected availability is required')
    .required('Expected availability is required'),
});
