import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { api, unauthorizedHandler } from 'services';
import { LocalStorage } from 'helpers';
import { AuthData } from 'services/auth';
import { User } from 'services/users';
import { useProfilePage } from 'modules/Profile/ProfilePage';

const GlobalContext = React.createContext<{
  isLoggedIn: boolean;
  user: User | null;
  loginUser: (authData: AuthData) => void;
  logoutUser: () => void;
  updateUser: (user: User) => void;
}>({
  isLoggedIn: true,
  user: null,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  loginUser: (authData: AuthData) => {},
  logoutUser: () => {},
  updateUser: (user: User) => {},
});

export const GlobalProvider = ({ children }: Props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!LocalStorage.getToken());
  const [user, setUser] = useState<User | null>(LocalStorage.getUser());
  const queryClient = useQueryClient();
  const { profileForm } = useProfilePage();

  const loginUser = useCallback((authData: AuthData) => {
    LocalStorage.setToken(authData.token);
    setUser(authData.user);
    setIsLoggedIn(true);
  }, []);

  const logoutUser = useCallback(() => {
    LocalStorage.removeToken();
    queryClient.removeQueries();
    setUser(null);
    setIsLoggedIn(false);
  }, [queryClient]);

  const updateUser = useCallback((updatedUser: User) => {
    LocalStorage.setUser(updatedUser);
    setUser(updatedUser);
  }, []);

  // Sync user state with local storage
  useEffect(() => {
    if (user) {
      LocalStorage.setUser(user);
    } else {
      LocalStorage.removeUser();
    }
  }, [user]);

  // Handle when server returns 401
  useEffect(() => {
    const interceptor = api.interceptors.response.use((config: any) => config, unauthorizedHandler(logoutUser));
    return () => {
      api.interceptors.request.eject(interceptor);
    };
  }, [logoutUser]);

  // Wrapped in useMemo because rerendering cycles
  const value = useMemo(
    () => ({ isLoggedIn, user, loginUser, logoutUser, updateUser, profileForm }),
    [isLoggedIn, user, loginUser, logoutUser, updateUser, profileForm],
  );

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalProvider = () => {
  return useContext(GlobalContext);
};

export const useAuth = () => {
  const { isLoggedIn, user, loginUser, logoutUser, updateUser } = useGlobalProvider();
  return { isLoggedIn, user, loginUser, logoutUser, updateUser };
};

interface Props {
  children: React.ReactNode;
}
