import { Address } from '../addresses';

export enum DeliveryWindow {
  Morning = 'morning',
  LateMorning = 'lateMorning',
  Noon = 'noon',
  LateNoon = 'lateNoon',
  Afternoon = 'afterNoon',
}

export enum PickupType {
  Today = 'today',
  Expedited = 'expedited',
  Scheduled = 'schedule',
}

export enum PaymentStatus {
  Paid = 'PAID',
  Pending = 'PENDING',
  Failed = 'FAILED',
}

export const DeliveryWindowTime: Record<string, string> = {
  morning: '8:00 AM - 12:00 PM',
  lateMorning: '10:00 AM - 2:00 PM',
  noon: '12:00 PM - 4:00 PM',
  lateNoon: '2:00 PM - 6:00 PM',
  afterNoon: '4:00 PM - 8:00 PM',
};

export const PickupTypeName: Record<string, string> = {
  today: 'Today',
  expedited: 'Expedited',
  schedule: 'Scheduled',
};
export interface Order {
  id: string;
  customerId: string;
  customerName: string;
  deliveryAddresses: string[];
  deliveryWindow: DeliveryWindow | null;
  driverGroupId: string;
  driverId: string;
  driverName: string;
  driverPayment: number | null;
  helperPayment: number | null;
  merchantId: string;
  promoCodeId: string;
  loadHelperCount: number | null;
  itemsDescription: string;
  unloadHelperCount: number | null;
  weight: number | null;
  tipOverride: number | null;
  startDateTime: string;
  totalMileage: number | null;
  mileagePrice: number | null;
  bookingPrice: number | null;
  weightPrice: number | null;
  firstHelperLoadPrice: number | null;
  firstHelperUnloadPrice: number | null;
  secondHelperLoadPrice: number | null;
  secondHelperUnloadPrice: number | null;
  expeditedFee: number | null;
  trailerPrice: number | null;
  loadHelperPrice: number | null;
  unloadHelperPrice: number | null;
  tipPrice: number | null;
  finalPrice: number | null;
  orderStatus: string;
  paymentStatus: PaymentStatus | null;
  paymentTransactionId: string;
  paymentDate: string;
  pickupAddress: string;
  pickupType: PickupType | null;
  merchantOrderStatus: string;
  extraTips: number | null;
  paymentType: string;
  deviceType: string;
  stops: OrderStop[];
}

export interface OrderStop {
  id: string;
  contactName: string;
  contactPhone: string;
  deliveryDateTime: string;
  driverDeliveryNote: string;
  fromAddress: Address | null;
  itemHeight: number | null;
  itemLength: number | null;
  itemQuantity: number | null;
  itemsToBeDeliveredNote: string;
  itemWeight: number | null;
  itemWidth: number | null;
  orderId: string;
  pickupDateTime: string;
  pickupNote: string;
  senderPersonName: string;
  senderPersonPhone: string;
  shippingDistance: number | null;
  shippingDistancePrice: number | null;
  stopNumber: number | null;
  toAddress: Address | null;
}
