import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { Control, useController } from 'react-hook-form';

export const Select = ({ name, control, onChange, options = [], error = false, optionLabel }: Props) => {
  const { field } = useController({
    control,
    name,
  });
  const [selected, setSelected] = useState<any>('');

  useEffect(() => {
    if (checkIfString(field.value)) {
      setSelected(field.value);
    } else {
      setSelected(options.find((o: any) => o.id === field.value));
    }
  }, [field.value, options]);

  const clearInput = (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    ev.stopPropagation();
    field.onChange(null);
    if (onChange) onChange(null);
  };

  return (
    <Listbox
      value={selected}
      onChange={(option: any) => {
        if (onChange) onChange(option);
        if (checkIfString(option)) {
          field.onChange(option);
        } else {
          field.onChange(option?.id);
        }
      }}
    >
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button
              className={clsx(
                'relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm h-9',
                {
                  'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500': !error,
                  'border-red-300 placeholder-red-400 focus:ring-red-500 focus:border-red-500': error,
                },
              )}
              ref={field.ref}
            >
              <span className="block truncate">{resolveOptionLabel(selected)}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
              <span className="absolute inset-y-0 right-6 flex items-center pr-2 hover:cursor-pointer">
                <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" onClick={clearInput} />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      clsx(
                        active ? 'text-white bg-primary-500' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-8 pr-4',
                      )
                    }
                    value={option}
                  >
                    {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                    {({ selected, active }) => (
                      <>
                        <span className={clsx(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {resolveOptionLabel(option, optionLabel)}
                        </span>

                        {selected ? (
                          <span
                            className={clsx(
                              active ? 'text-white' : 'text-primary-500',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

interface Props {
  name: string;
  control: Control<any, any>;
  onChange?: (option: any | null) => void;
  options?: any[];
  error?: boolean;
  optionLabel?: string;
}

const resolveOptionLabel = (option: any, optionLabel?: string) => {
  if (checkIfString(option) || option === undefined) {
    return option;
  }

  return optionLabel ? option[optionLabel] : option.name;
};

const checkIfString = (option: any) => typeof option === 'string';
