import { FormItem, Input } from 'components';
import { addressToString } from 'services/addresses/helpers';
import { DeliveryWindowTime, Order, OrderStop } from 'services/orders';

const getDeliveryWindowTime = (deliveryWindow: string | null): string => {
  if (!deliveryWindow) return '';

  return DeliveryWindowTime[deliveryWindow] || '';
};

export const OrderDeliveryDetails = ({ orderStop, order }: Props) => {
  const deliveryAddress = orderStop?.toAddress;

  return (
    <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
      <FormItem label="Delivery Window">
        <Input type="text" disabled value={getDeliveryWindowTime(order?.deliveryWindow)} />
      </FormItem>

      <FormItem label="Delivery Address">
        <Input type="text" disabled value={deliveryAddress ? addressToString(deliveryAddress) : ''} />
      </FormItem>

      <FormItem label="Contact for Delivery">
        <Input type="text" disabled value={orderStop.contactName} />
      </FormItem>

      <FormItem label="Phone for Delivery">
        <Input type="text" disabled value={orderStop.contactPhone} />
      </FormItem>

      <FormItem label="Delivery Information">
        <Input type="text" disabled value={orderStop.driverDeliveryNote} />
      </FormItem>

      <FormItem label="Items to be delivered">
        <Input type="text" disabled value={orderStop.itemsToBeDeliveredNote} />
      </FormItem>
    </div>
  );
};

interface Props {
  orderStop: OrderStop;
  order: Order;
}
