import clsx from 'clsx';

import { Button, Container, Divider, Header } from 'components';
import { useDeliveryPage } from './DeliveryTypePageProvider';

export const DeliveryTypePage = () => {
  const { driverGroups, watch, setValue, onSubmit, driverGroupsLoading } = useDeliveryPage();

  const selectedGroupId = watch('driverGroupId');

  const handleGroupClick = (id: string) => () => {
    setValue('driverGroupId', id);
  };

  return (
    <>
      <Header title={'Delivery Type'}>
        <Button type="submit" onClick={onSubmit} label="Save" form="infoForm" />
      </Header>

      <Container isLoading={driverGroupsLoading}>
        <div className="bg-white overflow-hidden shadow rounded-lg px-12 py-10">
          <div className="px-4 py-5 sm:p-6">
            <Divider title="Delivery Type" />
            {driverGroups.map(({ id, name, description }) => (
              <div
                className={clsx(`flex flex-col rounded-lg overflow-hidden border lg:mx-20 p-8 m-8 cursor-pointer`, {
                  'border-red-500': selectedGroupId === id,
                })}
                key={id}
                onClick={handleGroupClick(id)}
              >
                <p className="text-lg">{name}</p>
                <p className="text-sm">{description}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};
