import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Container, Header } from 'components';

dayjs.extend(customParseFormat);

export function DashboardPage() {
  return (
    <>
      <Header title="Dashboard" />
      <Container>
        <div className="bg-white overflow-hidden shadow rounded-lg px-12 py-10 flex-1 justify-center flex items-center">
          <h1>Hello from Dashboard!</h1>
        </div>
      </Container>
    </>
  );
}
