import clsx from 'clsx';
import { ReactNode } from 'react';

type FormItemProps = {
  label?: ReactNode;
  htmlFor?: string;
  required?: boolean;
  help?: ReactNode;
  error?: ReactNode;
} & JSX.IntrinsicElements['div'];

export function FormItem({
  label,
  htmlFor,
  children,
  required = false,
  help = null,
  error = null,
  ...props
}: FormItemProps) {
  return (
    <div {...props}>
      {label ? (
        <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
          {label}
          {required ? <span className="text-red-500">*</span> : null}
        </label>
      ) : null}
      <div className={label ? 'mt-1' : undefined}>{children}</div>
      {error || help ? (
        <div className={clsx('mt-2 text-sm', !error ? 'text-gray-500' : 'text-red-500')}>{error || help}</div>
      ) : null}
    </div>
  );
}
