import { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage } from 'helpers';
import { fetchProfile, putProfile, ProfileForm, defaultProfileForm, profileToProfileForm } from 'services';

import { INFO_VALIDATION_SCHEMA } from './const';

const InfoPageContext = createContext<{
  formState: FormState<ProfileForm>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<ProfileForm>;
  control: Control<ProfileForm, any>;
}>({} as any);

// This will be used in case we state shared inside the module
export const InfoPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: info } = useQuery(['info'], () => fetchProfile());
  const { mutateAsync: updateInfo } = useMutation(putProfile);

  const { handleSubmit, register, formState, reset, control } = useForm<ProfileForm>({
    defaultValues: defaultProfileForm,
    resolver: yupResolver(INFO_VALIDATION_SCHEMA),
  });

  const profileForm = useMemo(() => {
    if (info) {
      return profileToProfileForm(info);
    }
    return defaultProfileForm;
  }, [info]);

  useEffect(() => {
    reset(profileForm);
  }, [profileForm]);

  const onSubmit = handleSubmit(async (values: ProfileForm) => {
    try {
      await updateInfo(values);
      showMessage('Info successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('infos');
      navigate('/info');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, onSubmit, register, control }),
    [formState, onSubmit, register, control],
  );

  return <InfoPageContext.Provider value={providerValue}>{children}</InfoPageContext.Provider>;
};

export const useInfoPage = () => {
  return useContext(InfoPageContext);
};

interface Props {
  children?: React.ReactNode;
}
