import { api } from 'services';
import { resToImage } from './transformations';

import { Image } from './types';

export const getImageFile = async (imageUrl: string): Promise<File> => {
  const resp = await api.get(imageUrl, { responseType: 'arraybuffer' });
  return new File([resp.data], 'Test');
};

export const postImage = async (formData: FormData): Promise<Image> => {
  const resp = await api.post(`/storage/files`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return resToImage(resp.data);
};
