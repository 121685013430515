import { useState } from 'react';

import { useDebounce } from './debounce';

export interface DataWithMeta<T = any> {
  meta: MetaData;
  data: T[];
}

export interface MetaData {
  pageNumber: number;
  pageSize: number;
  totalRows: number;
  totalPages: number;
  sortDirection: SortDirection;
}

export interface PaginationFilters {
  [key: string]: string | number | boolean;
}

export interface PaginationRequest {
  page?: number;
  pageSize?: number;
  search?: string;
  sortBy?: string;
  sortDirection?: SortDirection;
  filters?: PaginationFilters;
}

export enum SortDirection {
  None = '',
  Asc = 'asc',
  Desc = 'desc',
}

export const usePagination = (defaultPagination?: PaginationRequest) => {
  const [currentPage, setCurrentPage] = useState(defaultPagination?.page ?? 1);
  const [pageSize, setPageSize] = useState(defaultPagination?.pageSize ?? 10);
  const [sortBy, setSortBy] = useState(defaultPagination?.sortBy ?? '');
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    defaultPagination?.sortDirection ?? SortDirection.None,
  );
  const [search, setSearch] = useState(defaultPagination?.search ?? '');
  const [filters, setFilters] = useState<PaginationFilters>(defaultPagination?.filters ?? {});
  const debouncedSearch = useDebounce<string>(search, 500);

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  const onSortChange = (accessor: string, sort: SortDirection) => {
    setCurrentPage(1);
    setSortBy(accessor);
    setSortDirection(sort);
  };

  const onSearchChange = (searchValue: string) => {
    setCurrentPage(1);
    setSearch(searchValue);
  };

  const onSearchReset = () => {
    setSearch('');
  };

  const onFiltersChange = (newFilters: PaginationFilters) => {
    setFilters(newFilters);
  };

  return {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onSearchReset,
    onFiltersChange,
  };
};

export const paginationRequestToUrl = (url: string, pagination: PaginationRequest) => {
  let newUrl = `${url}?page=${pagination.page}&pageSize=${pagination.pageSize}`;

  if (pagination.sortBy) newUrl += `&sortBy=${pagination.sortBy}`;
  if (pagination.sortDirection) newUrl += `&sortDirection=${pagination.sortDirection}`;
  if (pagination.search) newUrl += `&search=${pagination.search}`;
  if (pagination.filters) {
    const filters = Object.keys(pagination.filters)
      .map((key) => `${key}=${pagination.filters![key]}`)
      .join('&');

    newUrl += `&filter=${encodeURI(filters)}`;
  }

  return newUrl;
};
