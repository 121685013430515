import { ImageUpload } from 'components';

import { useInfoPage } from '../../InfoPageProvider';

export const Images = () => {
  const { control, formState } = useInfoPage();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
      <ImageUpload
        name="driversLicenseFile"
        control={control}
        label="Drivers Licence"
        required
        error={formState.errors.driversLicenseFile?.url?.message}
      />

      <ImageUpload
        name="vehicleInsuranceFile"
        control={control}
        label="Vehicle Insurance"
        required
        error={formState.errors.vehicleInsuranceFile?.url?.message}
      />

      <ImageUpload
        name="vehicleRegistrationFile"
        control={control}
        label="Vehicle Registration"
        required
        error={formState.errors.vehicleRegistrationFile?.url?.message}
      />
    </div>
  );
};
