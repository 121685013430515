import { PageType } from 'helpers';
import { idAndUrlToImage, initialImage, resToUser } from 'services';

import { Profile, ProfileForm } from './types';

export const resToProfile = (res: any): Profile => {
  const driverGroupIds = res.driverGroupIds || [];
  return {
    id: res.id,
    approveDenyNote: res.approveDenyNote ?? '',
    description: res.description ?? '',
    driverGroupId: driverGroupIds[0] ?? '',
    driversLicenseExpirationDate: res.driversLicenseExpirationDate ?? null,
    driversLicenseNumber: res.driversLicenseNumber ?? '',
    driversLicenseState: res.driversLicenseState ?? '',
    driversLicenseFileId: res.driversLicenseFileId ?? '',
    driversLicenseFileUrl: res.driversLicenseFileUrl ?? '',
    expectedAvailabilityId: res.expectedAvailabilityId ?? '',
    firstHelperPaymentPercentage: res.firstHelperPaymentPercentage ?? null,
    isHelper: res.isHelper ?? false,
    maximumDrivingDistanceMiles: res.maximumDrivingDistanceMiles ?? null,
    orderPaymentPercentage: res.orderPaymentPercentage ?? null,
    profilePictureFileId: res.profilePictureFileId ?? '',
    profilePictureFileUrl: res.profilePictureFileUrl ?? '',
    secondHelperPaymentPercentage: res.secondHelperPaymentPercentage ?? null,
    status: res.status ?? '',
    user: res.user ? resToUser(res.user) : null,
    vehicleColor: res.vehicleColor ?? '',
    vehicleInsuranceFileId: res.vehicleInsuranceFileId ?? '',
    vehicleInsuranceFileUrl: res.vehicleInsuranceFileUrl ?? '',
    vehicleRegistrationFileId: res.vehicleRegistrationFileId ?? '',
    vehicleRegistrationFileUrl: res.vehicleRegistrationFileUrl ?? '',
    vehicleMake: res.vehicleMake ?? '',
    vehicleModel: res.vehicleModel ?? '',
    vehicleYear: res.vehicleYear ?? null,
    driverGroupName: res.driverGroupName ?? '',
  };
};

export const profileToPutReq = (data: ProfileForm): any => {
  const payload: any = {
    user: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      dateOfBirth: data.dateOfBirth?.toISOString() ?? null,
      isDriver: true,
      isCustomer: data.isCustomer,
      isMerchant: data.isMerchant,
      isAdmin: data.isAdmin,
      homeAddress: {
        street1: data.address1,
        street2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: 'US',
        lat: 0,
        lng: 0,
      },
    },
    description: data.description,
    driverGroupIds: [data.driverGroupId],
    driversLicenseNumber: data.driversLicenseNumber,
    driversLicenseState: data.driversLicenseState,
    driversLicenseExpirationDate: data.driversLicenseExpirationDate?.toISOString() ?? null,
    vehicleMake: data.vehicleMake,
    vehicleModel: data.vehicleModel,
    vehicleYear: data.vehicleYear,
    vehicleColor: data.vehicleColor,
    isHelper: data.isHelper,
    maximumDrivingDistanceMiles: data.maximumDrivingDistanceMiles,
    expectedAvailabilityId: data.expectedAvailabilityId,
  };

  if (data.driversLicenseFile.id !== PageType.Create) {
    payload.driversLicenseFileId = data.driversLicenseFile.id;
  }

  if (data.profilePictureFile.id !== PageType.Create) {
    payload.profilePictureFileId = data.profilePictureFile.id;
  }

  if (data.vehicleInsuranceFile.id !== PageType.Create) {
    payload.vehicleInsuranceFileId = data.vehicleInsuranceFile.id;
  }

  if (data.vehicleRegistrationFile.id !== PageType.Create) {
    payload.vehicleRegistrationFileId = data.vehicleRegistrationFile.id;
  }

  return payload;
};

export const profileToProfileForm = (profile: Profile): ProfileForm => {
  return {
    id: profile.id,
    password: '',
    firstName: profile.user?.firstName ?? '',
    lastName: profile.user?.lastName ?? '',
    email: profile.user?.email ?? '',
    phoneNumber: profile.user?.phoneNumber ?? '',
    dateOfBirth: profile.user?.dateOfBirth ? new Date(profile.user.dateOfBirth) : null,
    address1: profile.user?.homeAddress?.street1 ?? '',
    address2: profile.user?.homeAddress?.street2 ?? '',
    city: profile.user?.homeAddress?.city ?? '',
    state: profile.user?.homeAddress?.state ?? '',
    zip: profile.user?.homeAddress?.zip ?? '',
    driverGroupId: profile.driverGroupId,
    driversLicenseNumber: profile.driversLicenseNumber,
    driversLicenseState: profile.driversLicenseState,
    driversLicenseExpirationDate: profile.driversLicenseExpirationDate
      ? new Date(profile.driversLicenseExpirationDate)
      : null,
    driversLicenseFile: profile.driversLicenseFileUrl
      ? idAndUrlToImage(profile.driversLicenseFileId, profile.driversLicenseFileUrl)
      : initialImage,
    vehicleMake: profile.vehicleMake,
    vehicleModel: profile.vehicleModel,
    vehicleYear: profile.vehicleYear?.toString() ?? '',
    vehicleColor: profile.vehicleColor,
    driverGroupName: profile.driverGroupName,
    isHelper: profile.isHelper,
    maximumDrivingDistanceMiles: profile.maximumDrivingDistanceMiles?.toString() ?? '',
    expectedAvailabilityId: profile.expectedAvailabilityId,
    profilePictureFile: profile.profilePictureFileUrl
      ? idAndUrlToImage(profile.profilePictureFileId, profile.profilePictureFileUrl)
      : initialImage,
    vehicleInsuranceFile: profile.vehicleInsuranceFileUrl
      ? idAndUrlToImage(profile.vehicleInsuranceFileId, profile.vehicleInsuranceFileUrl)
      : initialImage,
    vehicleRegistrationFile: profile.vehicleRegistrationFileUrl
      ? idAndUrlToImage(profile.vehicleRegistrationFileId, profile.vehicleRegistrationFileUrl)
      : initialImage,
    description: profile.description,
    isAdmin: profile.user?.isAdmin ?? false,
    isCustomer: profile.user?.isCustomer ?? false,
    isDriver: profile.user?.isDriver ?? false,
    isMerchant: profile.user?.isMerchant ?? false,
  };
};
