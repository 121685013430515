import { FormItem, Input, DatePicker } from 'components';
import { Select } from 'components/Select';
import { ExpectedAvailability } from 'services';
import { useInfoPage } from '../../InfoPageProvider';
import { USAStates } from 'services';

export const VehicleInformation = () => {
  const { formState, register, control } = useInfoPage();

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
      <FormItem
        label="License Number"
        htmlFor="driversLicenseNumber"
        error={formState.errors.driversLicenseNumber?.message}
        required
      >
        <Input
          {...register('driversLicenseNumber')}
          id="driversLicenseNumber"
          type="text"
          placeholder="Enter license number"
          autoComplete="driversLicenseNumber"
          required
          error={!!formState.errors.driversLicenseNumber?.message}
        />
      </FormItem>

      <FormItem
        label="License State"
        htmlFor="driversLicenseState"
        error={formState.errors.driversLicenseState?.message}
        required
      >
        <Select
          name="driversLicenseState"
          options={Object.values(USAStates)}
          control={control}
          error={!!formState.errors.driversLicenseState?.message}
        />
      </FormItem>

      <FormItem
        label="License Expiration Date"
        htmlFor="driversLicenseExpirationDate"
        error={formState.errors.driversLicenseExpirationDate?.message}
        required
      >
        <DatePicker
          control={control}
          name="driversLicenseExpirationDate"
          error={!!formState.errors.driversLicenseExpirationDate?.message}
        />
      </FormItem>

      <FormItem label="Vehicle Color" htmlFor="vehicleColor" error={formState.errors.vehicleColor?.message} required>
        <Input
          {...register('vehicleColor')}
          id="vehicleColor"
          type="text"
          placeholder="Enter vehicle color"
          autoComplete="vehicleColor"
          required
          error={!!formState.errors.vehicleColor?.message}
        />
      </FormItem>

      <FormItem label="Vehicle Make" htmlFor="vehicleMake" error={formState.errors.vehicleMake?.message} required>
        <Input
          {...register('vehicleMake')}
          id="vehicleMake"
          type="text"
          placeholder="Enter vehicle make"
          autoComplete="vehicleMake"
          required
          error={!!formState.errors.vehicleMake?.message}
        />
      </FormItem>

      <FormItem label="Vehicle Model" htmlFor="vehicleModel" error={formState.errors.vehicleModel?.message} required>
        <Input
          {...register('vehicleModel')}
          id="vehicleModel"
          type="text"
          placeholder="Enter vehicle model"
          autoComplete="vehicleModel"
          required
          error={!!formState.errors.vehicleModel?.message}
        />
      </FormItem>

      <FormItem label="Vehicle Year" htmlFor="vehicleYear" error={formState.errors.vehicleYear?.message} required>
        <Input
          {...register('vehicleYear')}
          id="vehicleYear"
          type="number"
          placeholder="Enter vehicle year"
          autoComplete="vehicleYear"
          required
          error={!!formState.errors.vehicleYear?.message}
        />
      </FormItem>

      <FormItem label="Driver Group" htmlFor="driverGroupName">
        <Input
          {...register('driverGroupName')}
          id="driverGroupName"
          type="text"
          placeholder="Enter driver group name"
          autoComplete="driverGroupName"
          disabled
        />
      </FormItem>

      <FormItem
        label="How far are you willing to drive?"
        htmlFor="maximumDrivingDistanceMiles"
        error={formState.errors.maximumDrivingDistanceMiles?.message}
        required
      >
        <Input
          {...register('maximumDrivingDistanceMiles')}
          id="maximumDrivingDistanceMiles"
          type="number"
          placeholder="Enter vehicle type"
          autoComplete="maximumDrivingDistanceMiles"
          required
          error={!!formState.errors.maximumDrivingDistanceMiles?.message}
        />
      </FormItem>

      <FormItem
        label="Expected availability"
        htmlFor="expectedAvailabilityId"
        required
        error={formState.errors.expectedAvailabilityId?.message}
      >
        <Select
          control={control}
          name="expectedAvailabilityId"
          options={Object.values(ExpectedAvailability)}
          error={!!formState.errors.expectedAvailabilityId?.message}
        />
      </FormItem>

      <FormItem label="Description" htmlFor="description" error={formState.errors.description?.message}>
        <Input
          {...register('description')}
          id="description"
          type="text"
          placeholder="Enter vehicle type"
          autoComplete="description"
          required
          error={!!formState.errors.description?.message}
        />
      </FormItem>
    </div>
  );
};
