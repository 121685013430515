import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import { usePagination } from 'helpers';
import { fetchOrders, Order } from 'services/orders';

// Drivers page state
export const useOrdersPage = () => {
  const navigate = useNavigate();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    search,
    debouncedSearch,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const { data: queryData, isLoading: driversLoading } = useQuery(
    ['orders', currentPage, pageSize, sortBy, sortDirection, debouncedSearch],
    () => fetchOrders({ page: currentPage, pageSize, sortBy, sortDirection, search: debouncedSearch }),
  );

  const orders = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: Order) => {
    navigate(`/orders/${row.id}`);
  };

  return {
    orders,
    metaData,
    currentPage,
    search,
    isLoading: driversLoading,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onSortChange,
  };
};
