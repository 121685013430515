import { DataWithMeta, PaginationRequest } from 'helpers';

import { api } from '../api';
import { resToOrder } from './transformations';
import { Order } from './types';

export const fetchOrders = async (pagination: PaginationRequest): Promise<DataWithMeta<Order>> => {
  let url = `/drivers/me/orders?page=${pagination.page}&pageSize=${pagination.pageSize}`;

  if (pagination.sortBy) url += `&sortBy=${pagination.sortBy}`;
  if (pagination.sortDirection) url += `&sortDirection=${pagination.sortDirection}`;
  if (pagination.search) url += `&search=${pagination.search}`;

  const resp = await api.get(url);

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToOrder),
  };
};

export const fetchOrder = async (id: string): Promise<Order> => {
  const resp = await api.get(`/orders/${id}`);
  return resToOrder(resp.data);
};
