import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { MainLayout } from 'components';
import { GlobalProvider } from 'GlobalProvider';
import { SignIn } from 'modules/SignIn';
import { DashboardPage } from 'modules/Dashboard';
import { ProfilePage, ProfilePageProvider, ProfileProvider } from 'modules/Profile';
import { InfoPage, InfoPageProvider, InfoProvider } from 'modules/Info';
import { OrderPage, OrderPageProvider, OrdersPage, OrdersProvider } from 'modules/Orders';
import { SignUp } from 'modules/SignUp';
import { SignUpProvider } from 'modules/SignUp/SignUpProvider';
import { DeliveryTypePage, DeliveryTypePageProvider, DeliveryTypeProvider } from 'modules/DeliveryType';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

dayjs.extend(localizedFormat);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<DashboardPage />} />
            <Route path="info" element={<InfoProvider />}>
              <Route element={<InfoPageProvider />}>
                <Route index element={<InfoPage />} />
              </Route>
            </Route>
            <Route path="profile" element={<ProfileProvider />}>
              <Route element={<ProfilePageProvider />}>
                <Route index element={<ProfilePage />} />
              </Route>
            </Route>
            <Route path="orders" element={<OrdersProvider />}>
              <Route index element={<OrdersPage />} />
              <Route path=":id" element={<OrderPageProvider />}>
                <Route index element={<OrderPage />} />
              </Route>
            </Route>
            <Route path="delivery-type" element={<DeliveryTypeProvider />}>
              <Route element={<DeliveryTypePageProvider />}>
                <Route index element={<DeliveryTypePage />} />
              </Route>
            </Route>
          </Route>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUpProvider />}>
            <Route index element={<SignUp />} />
          </Route>
        </Routes>
      </GlobalProvider>
    </QueryClientProvider>
  );
}

export default App;
