import { PageType } from 'helpers';
import { initialImage } from 'services';
import { ProfileForm } from './types';

export const defaultProfileForm: ProfileForm = {
  id: PageType.Create,
  address1: '',
  address2: '',
  city: '',
  dateOfBirth: null,
  description: '',
  driverGroupId: '',
  driversLicenseExpirationDate: null,
  driversLicenseFile: initialImage,
  driversLicenseNumber: '',
  driversLicenseState: '',
  email: '',
  expectedAvailabilityId: '',
  firstName: '',
  isAdmin: false,
  isCustomer: false,
  isDriver: true,
  isHelper: false,
  isMerchant: false,
  lastName: '',
  maximumDrivingDistanceMiles: '',
  password: '',
  phoneNumber: '',
  profilePictureFile: initialImage,
  state: '',
  vehicleColor: '',
  vehicleInsuranceFile: initialImage,
  vehicleMake: '',
  vehicleModel: '',
  vehicleRegistrationFile: initialImage,
  driverGroupName: '',
  vehicleYear: '',
  zip: '',
};

export enum ExpectedAvailability {
  AFewDeliveriesAWeek = 'A Few Deliveries A Week',
  PartTimeMornings = 'Part Time Mornings',
  PartTimeEvnings = 'Part Time Evnings',
  PartTimeWeekends = 'Part Time Weekends',
  FullTime = 'Full Time',
}

export enum USAStates {
  Alabama = 'Alabama',
  Alaska = 'Alaska',
  AmericanSamoa = 'American Samoa',
  Arizona = 'Arizona',
  Arkansas = 'Arkansas',
  California = 'California',
  Colorado = 'Colorado',
  Connecticut = 'Connecticut',
  Delaware = 'Delaware',
  DistrictOfColumbia = 'District Of Columbia',
  FederatedStatesOfMicronesia = 'Federated States Of Micronesia',
  Florida = 'Florida',
  Georgia = 'Georgia',
  Guam = 'Guam',
  Hawaii = 'Hawaii',
  Idaho = 'Idaho',
  Illinois = 'Illinois',
  Indiana = 'Indiana',
  Iowa = 'Iowa',
  Kansas = 'Kansas',
  Kentucky = 'Kentucky',
  Louisiana = 'Louisiana',
  Maine = 'Maine',
  MarshallIslands = 'Marshall Islands',
  Maryland = 'Maryland',
  Massachusetts = 'Massachusetts',
  Michigan = 'Michigan',
  Minnesota = 'Minnesota',
  Mississippi = 'Mississippi',
  Missouri = 'Missouri',
  Montana = 'Montana',
  Nebraska = 'Nebraska',
  Nevada = 'Nevada',
  NewHampshire = 'New Hampshire',
  NewJersey = 'New Jersey',
  NewMexico = 'New Mexico',
  NewYork = 'New York',
  NorthCarolina = 'North Carolina',
  NorthDakota = 'North Dakota',
  NorthernMarianaIslands = 'Northern Mariana Islands',
  Ohio = 'Ohio',
  Oklahoma = 'Oklahoma',
  Oregon = 'Oregon',
  Palau = 'Palau',
  Pennsylvania = 'Pennsylvania',
  PuertoRico = 'Puerto Rico',
  RhodeIsland = 'Rhode Island',
  SouthCarolina = 'South Carolina',
  SouthDakota = 'South Dakota',
  Tennessee = 'Tennessee',
  Texas = 'Texas',
  Utah = 'Utah',
  Vermont = 'Vermont',
  VirginIslands = 'Virgin Islands',
  Virginia = 'Virginia',
  Washington = 'Washington',
  WestVirginia = 'West Virginia',
  Wisconsin = 'Wisconsin',
  Wyoming = 'Wyoming',
}
