import { Address } from './types';

export const resToAddress = (res: any): Address => {
  return {
    id: res.id ?? '',
    city: res.city ?? '',
    country: res.country ?? '',
    lat: res.lat ?? 0,
    lng: res.lng ?? 0,
    state: res.state ?? '',
    street1: res.street1 ?? '',
    street2: res.street2 ?? '',
    zip: res.zip ?? '',
  };
};
