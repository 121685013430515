import { Divider, ImageUpload } from 'components';
import { useSignUpPage } from 'modules/SignUp/SignUpProvider';

export const SignUpStep6 = () => {
  const { formState, control } = useSignUpPage();

  return (
    <>
      <Divider title="Profile Picture" titlePosition="center" />

      <div className="flex justify-center">
        <ImageUpload
          control={control}
          className="max-w-xs"
          name="profilePictureFile"
          help="The best photos are shot from the shoulders up with a solid background with only you in the picture."
          error={formState.errors.vehicleInsuranceFile?.url?.message}
        />
      </div>
    </>
  );
};
