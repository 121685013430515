import { createContext, useContext, useEffect, useMemo } from 'react';
import { FormState, useForm, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { MessageType, showMessage } from 'helpers';
import { fetchProfile, putProfile, ProfileForm, defaultProfileForm, profileToProfileForm } from 'services';
import { DriverGroup, fetchDriverGroups } from 'services/driverGroups';

const DeliveryTypePageContext = createContext<{
  formState: FormState<ProfileForm>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  driverGroups: DriverGroup[];
  register: UseFormRegister<ProfileForm>;

  setValue: UseFormSetValue<ProfileForm>;
  watch: UseFormWatch<ProfileForm>;
  driverGroupsLoading: boolean;
}>({} as any);

// This will be used in case we state shared inside the module
export const DeliveryTypePageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: info } = useQuery(['info'], () => fetchProfile());
  const { data: driverGroupsData, isLoading: driverGroupsLoading } = useQuery(['driverGroups'], () =>
    fetchDriverGroups({ page: 1, pageSize: 100, sortBy: 'position', filters: { showInApp: true } }),
  );
  const { mutateAsync: updateInfo } = useMutation(putProfile);

  const { handleSubmit, register, formState, reset, setValue, watch } = useForm<ProfileForm>({
    defaultValues: defaultProfileForm,
  });

  const profileForm = useMemo(() => {
    if (info) {
      return profileToProfileForm(info);
    }
    return defaultProfileForm;
  }, [info]);

  const driverGroups = useMemo(() => driverGroupsData?.data || [], [driverGroupsData]);

  useEffect(() => {
    reset(profileForm);
  }, [profileForm]);

  const onSubmit = handleSubmit(async (values: ProfileForm) => {
    try {
      await updateInfo(values);
      showMessage('Deliver type successfully updated.', MessageType.Success);
      queryClient.invalidateQueries('infos');
      navigate('/delivery-type');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, onSubmit, setValue, driverGroups, register, watch, driverGroupsLoading }),
    [formState, onSubmit, driverGroups, register, watch],
  );

  return <DeliveryTypePageContext.Provider value={providerValue}>{children}</DeliveryTypePageContext.Provider>;
};

export const useDeliveryPage = () => {
  return useContext(DeliveryTypePageContext);
};

interface Props {
  children?: React.ReactNode;
}
