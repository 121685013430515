import { FormItem, Input } from 'components';
import { formatDate } from 'helpers';

import { useOrderPage } from '../../OrderPageProvider';

export const OrderSummary = () => {
  const { order } = useOrderPage();

  return (
    <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
      <FormItem label="ID">
        <Input type="text" disabled value={order.id} />
      </FormItem>

      <FormItem label="Start Date">
        <Input type="text" disabled value={order.startDateTime ? formatDate(new Date(order.startDateTime)) : ''} />
      </FormItem>

      <FormItem label="Driver">
        <Input type="text" disabled value={order.driverName} />
      </FormItem>

      <FormItem label="Customer">
        <Input type="text" disabled value={order.customerName} />
      </FormItem>

      <FormItem label="Status">
        <Input type="text" disabled value={order.orderStatus} />
      </FormItem>
    </div>
  );
};
