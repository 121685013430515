import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import clsx from 'clsx';

import { useAuth } from 'GlobalProvider';

export function AuthCardLayout({ title, children, subtitle, contentClassName }: AuthCardLayoutProps) {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-32 w-auto" src="/assets/images/logo.png" alt="logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{title}</h2>
        {subtitle}
      </div>

      <div className={clsx('mt-8 sm:mx-auto sm:w-full sm:max-w-md', contentClassName)}>
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">{children}</div>
      </div>
    </div>
  );
}

type AuthCardLayoutProps = {
  title?: string;
  children: ReactNode;
  subtitle?: ReactNode;
  contentClassName?: string;
  isLoading?: boolean;
};
