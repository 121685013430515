import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Control, FormState, useForm, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Outlet } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { DriverGroup, fetchDriverGroups } from 'services/driverGroups';
import { MessageType, showMessage } from 'helpers';
import { registerDriver } from 'services';

import { getValidationSchema, initialSignUpForm } from './consts';
import { SignUpForm } from './types';

const SignUpContext = createContext<{
  activeStep: number;
  control: Control<SignUpForm, any>;
  formState: FormState<SignUpForm>;
  driverGroups: DriverGroup[];
  register: UseFormRegister<SignUpForm>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onPrevious: () => void;
  setValue: UseFormSetValue<SignUpForm>;
  watch: UseFormWatch<SignUpForm>;
}>({} as any);

export const SignUpProvider = ({ children = <Outlet /> }: Props) => {
  const [activeStep, setActiveStep] = useState(1);

  const { data: driverGroupsData } = useQuery(['driverGroups'], () =>
    fetchDriverGroups({ page: 1, pageSize: 20, sortBy: 'position' }),
  );
  const { mutateAsync: signUpDriver } = useMutation(registerDriver);

  const driverGroups = useMemo(() => driverGroupsData?.data || [], [driverGroupsData]);

  const { handleSubmit, register, formState, control, setValue, watch } = useForm<SignUpForm>({
    defaultValues: initialSignUpForm,
    resolver: (data, context, options) => yupResolver(getValidationSchema(activeStep))(data, context, options),
    reValidateMode: 'onSubmit',
  });

  const isHelper = watch('isHelper');

  const driverGroupHelperId = useMemo(() => {
    const helperDriverGroup = driverGroups.find((driverGroup) => driverGroup.isHelper);

    if (helperDriverGroup) {
      return helperDriverGroup.id;
    }

    return '';
  }, [driverGroupsData]);

  const onSubmit = handleSubmit(async (form: SignUpForm) => {
    if (isHelper && activeStep !== 7) {
      setActiveStep(7);
      return;
    }

    if (activeStep < 7) {
      setActiveStep((old) => old + 1);
      return;
    }

    try {
      await signUpDriver(form.isHelper ? { ...form, driverGroupId: driverGroupHelperId } : form);
      setActiveStep(8);
    } catch (err: any) {
      showMessage(err.response?.data?.title || 'Something went wrong, please try again.', MessageType.Error);
      console.error(err);
    }
  });

  const onPrevious = useCallback(() => {
    if (activeStep === 7 && isHelper) {
      setActiveStep(2);
    } else {
      setActiveStep((old) => old - 1);
    }
  }, [activeStep]);

  const providerValue = useMemo(
    () => ({
      activeStep,
      control,
      formState,
      driverGroups,
      register,
      onSubmit,
      onPrevious,
      setValue,
      watch,
    }),
    [activeStep, control, formState, driverGroups, register, onSubmit, onPrevious, setValue, watch],
  );

  return <SignUpContext.Provider value={providerValue}>{children}</SignUpContext.Provider>;
};

export const useSignUpPage = () => {
  return useContext(SignUpContext);
};

interface Props {
  children?: React.ReactNode;
}
