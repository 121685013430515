import * as yup from 'yup';

import { initialImage } from 'services';

import { SignUpForm } from './types';

export const initialSignUpForm: SignUpForm = {
  // step 1
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  dateOfBirth: null,
  phoneNumber: '',

  // step 2
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',

  // step 3
  driversLicenseNumber: '',
  driversLicenseState: '',
  driversLicenseExpirationDate: null,
  vehicleColor: '',
  vehicleMake: '',
  vehicleModel: '',
  vehicleYear: '',
  maximumDrivingDistanceMiles: null,
  expectedAvailabilityId: '',
  driversLicenseFile: initialImage,
  isHelper: false,

  // step 4
  driverGroupId: '',

  // step 5
  vehicleInsuranceFile: initialImage,
  vehicleRegistrationFile: initialImage,

  // step 6
  profilePictureFile: initialImage,

  // step 7
  terms: false,
};

const STEP1_VALIDATION_SCHEMA = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('E-mail is invalid').required('E-mail is required'),
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
  dateOfBirth: yup.date().typeError('Date of birth is required').required('Date of birth is required'),
  phoneNumber: yup.string().required('Phone number is required'),
});

const STEP2_VALIDATION_SCHEMA = yup.object({
  address1: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup.string().required('Zip Code is required'),
});

const STEP3_VALIDATION_SCHEMA = yup.object({
  driversLicenseNumber: yup.string().when('isHelper', {
    is: false,
    then: yup.string().required('Drivers license number is required'),
  }),
  driversLicenseState: yup
    .string()
    .when('isHelper', { is: false, then: yup.string().required('Drivers license state is required') }),
  driversLicenseExpirationDate: yup
    .mixed()
    .when('isHelper', { is: false, then: yup.date().typeError('Drivers license expiration date is required') }),
  vehicleColor: yup.string().when('isHelper', { is: false, then: yup.string().required('Vehicle color is required') }),
  vehicleMake: yup.string().when('isHelper', { is: false, then: yup.string().required('Vehicle make is required') }),
  vehicleModel: yup.string().when('isHelper', { is: false, then: yup.string().required('Vehicle model is required') }),
  vehicleYear: yup.string().when('isHelper', { is: false, then: yup.string().required('Vehicle year is required') }),
  maximumDrivingDistanceMiles: yup
    .mixed()
    .when('isHelper', { is: false, then: yup.number().required('Maximum driving distance is required') }),
  expectedAvailabilityId: yup
    .string()
    .when('isHelper', { is: false, then: yup.string().required('Expected availability is required') }),
  driversLicenseFile: yup.mixed().when('isHelper', {
    is: false,
    then: yup.object({
      url: yup.string().required('Drivers license file is required'),
    }),
  }),
});

const STEP4_VALIDATION_SCHEMA = yup.object({
  driverGroupId: yup.string().required('Delivery type is required'),
});

const STEP5_VALIDATION_SCHEMA = yup.object({
  vehicleInsuranceFile: yup.object({
    url: yup.string().required('Vehicle insurance is required'),
  }),
  vehicleRegistrationFile: yup.object({
    url: yup.string().required('Vehicle registration is required'),
  }),
});

const STEP6_VALIDATION_SCHEMA = yup.object({});

const STEP7_VALIDATION_SCHEMA = yup.object({
  terms: yup.boolean().oneOf([true], 'Terms and conditions must be accepted'),
});

export const getValidationSchema = (step: number) => {
  switch (step) {
    case 1:
      return STEP1_VALIDATION_SCHEMA;
    case 2:
      return STEP2_VALIDATION_SCHEMA;
    case 3:
      return STEP3_VALIDATION_SCHEMA;
    case 4:
      return STEP4_VALIDATION_SCHEMA;
    case 5:
      return STEP5_VALIDATION_SCHEMA;
    case 6:
      return STEP6_VALIDATION_SCHEMA;
    case 7:
      return STEP7_VALIDATION_SCHEMA;
    default:
      return yup.object();
  }
};
