import { Checkbox, Divider, FormItem } from 'components';
import { useSignUpPage } from 'modules/SignUp/SignUpProvider';

export const SignUpStep7 = () => {
  const { formState, register } = useSignUpPage();

  return (
    <>
      <Divider title="Terms &amp; Conditions" titlePosition="center" />

      <div className="flex justify-center">
        <div className="lg:max-w-4xl">
          <div className="flex flex-col gap-4 max-h-[40rem] overflow-y-auto mb-8">
            <div className="text-center p-10 bg-sky-300">
              <p className="text-lg">Please scroll to read the entire document before accepting.</p>
            </div>

            <p className="text-center">
              <strong>
                PONYEX APPLICATION
                <br />
                END USER LICENSE AGREEMENT
                <br />
                AND&nbsp;
                <br />
                TERMS OF USE
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              IMPORTANT &ndash; READ CAREFULLY BEFORE PROCEEDING. &nbsp;YOU, THE &ldquo;USER&rdquo; WHETHER A DRIVER OR
              A SENDER, AGREE AS FOLLOWS. &nbsp;YOUR USE OF ANY DOWNLOADABLE SOFTWARE PRODUCTS, FEATURES OR SERVICES
              AVAILABLE ON OR THROUGH THE PONYEX APP OR WEBSITE IS CONDITIONED UPON YOUR ACCEPTANCE OF AND COMPLIANCE
              WITH THE FOLLOWING AGREEMENT. YOU ACKNOWLEDGE AND AGREE THAT BY CLICKING ON THE AGREE BUTTON AS MAY BE
              DESIGNATED BY UGO NOW, INC., DOING BUSINESS AS PONYEX (&ldquo;PONYEX&rdquo;) TO SHOW THESE TERMS AND/OR TO
              INSTALL THE PONYEX APP OR ANY RELATED SOFTWARE (COLLECTIVELY &ldquo;PONYEX APP&rdquo;), YOU ARE ENTERING
              INTO A LEGALLY BINDING CONTRACT. YOU WILL NOT BE PERMITTED TO USE THE PONYEX APP AND ANY ASSOCIATED
              SERVICES UNLESS AND UNTIL YOU AGREE TO THESE AGREEMENTS AND ANY OTHER RULES OR POLICIES THAT PONYEX MAY
              CREATE AND MAKE AVAILABLE ON THIS APP OR WEBSITE FROM TIME TO TIME.
            </p>
            <p>
              You represent and warrant that you (i) have read and understand or have gained an understanding of these
              Agreements and agree to be bound by them; (ii) are if legal age in which you reside to enter into a
              binding contract; (iii) have all authority necessary to enter into these Agreements personally, or if on
              behalf of an organization, it is within the scope of your responsibilities and authority to enter into
              these Agreements for the organization; (iv) will not open more than one account/registration with PonyEx;
              and (v) that login/registration data, contact information and other information provided is current,
              complete, and accurate, that PonyEx can rely upon it to communicate with you and that if any such data or
              information changes you will update that data and/or information with PonyEx.
            </p>
            <p>
              These Agreements will commence on the date that you complete the installation of the PonyEx App (the{' '}
              <strong>&quot;Effective Date&quot;</strong>).
            </p>
            <p>
              <strong>END-USER LICENSE AGREEMENT FOR PONYEX APP</strong>
            </p>
            <p>
              <strong>1. TITLE.</strong> The PonyEx TM App is a copyrighted work and its logos, brands and identifiers
              are trademarks owned exclusively by &nbsp;PonyEx. PonyEx shall retain all right, title and interest in the
              PonyEx App and in all intellectual property rights therein. Subject to PonyEx&rsquo;s ownership, no
              license or other rights of any kind are granted or conveyed except for the limited use license expressly
              provided herein. &nbsp;You agree that you will not remove, conceal or otherwise change any copyright,
              trademark, patent or other proprietary notice or source identifier contained in or associated with the
              PonyEx App.
            </p>
            <p>
              Under this End-User License Agreement you only receive a copy of the PonyEx App. You shall not duplicate,
              distribute, offer, loan, transfer, encumber, sell or otherwise dispose of the copy of the PonyEx App you
              receive to any third party without having received prior written authorization from PonyEx. If you do so
              without PonyEx&rsquo;s permission, the transfer will be deemed void because the limited use license is not
              transferable.
            </p>
            <p>
              <strong>2. LIMITED LICENSE.</strong> PonyEx hereby grants to you a limited, nonexclusive,
              non-transferable, non-sublicensable license to use the PonyEx App on a mobile device or personal computer
              that you may own or control to use, display and execute the PonyEx App during the term of this End-User
              License Agreement for your personal convenience. &nbsp;This limited license creates no legal, delivery
              business relationship, commitment or obligation between a transportation provider (&ldquo;Driver&rdquo;),
              PonyEx and/or any service or product owner for whom Driver may provide transportation service(s)
              (&ldquo;Sender&rdquo;).
            </p>
            <p>
              <strong>3. RESTRICTION ON USE. &nbsp;</strong>You agree that you will not and that you will not permit or
              allow any other person, directly or indirectly to (a) copy, reproduce, modify, redistribute, sublicense,
              transfer, rent, lease, sell, resell, lend, assign, publish, transmit, create derivative work(s), disclose
              or otherwise disseminate, exploit or make available any portion or all of the PonyEx App to any third
              party in any form; (b) reverse engineer, decompile, disassemble, or otherwise attempt to derive the source
              code for the PonyEx App; or (c) post, cast or otherwise make the PonyEx App available over a computer
              network where it could be used by multiple devices at the same time.
            </p>
            <p>
              <strong>4. UPDATES OF THE PONYEX APP.</strong>&nbsp; During the term of this End-User License Agreement,
              PonyEx may, in its sole discretion, develop updates and may notify you that it has released an updated
              version of the PonyEx App (the <strong>&quot;Updated App&quot;</strong>). You may download the Updated App
              and use the Updated App instead of the prior version. Any Updated App will also be considered
              &quot;App&quot; for purposes of this End-User License Agreement. &nbsp;PonyEx reserves the right at any
              time and from time to time to supplement, add, change, modify, alter or discontinue temporarily or
              permanently any and all functionality or features of the PonyEx App with or without notice. &nbsp;You
              agree that PonyEx shall not be liable to you for any modification, suspension or discontinuance of the
              PonyEx App or any features or functions of the PonyEx App. &nbsp;If PonyEx is required due to any
              third-party rights to modify the PonyEx App you agree to discontinue use of any prior version of the
              PonyEx App and commence using a modified version of the PonyEx App.
            </p>
            <p>
              <strong>5. TERMINATION.</strong> This End-User License Agreement and the limited license granted hereunder
              shall terminate immediately if you breach any material term or condition hereof. In the event of a
              termination of this End-User License Agreement, you agree to discontinue all use of the PonyEx App,
              promptly destroy or have destroyed all copies of the PonyEx App, and upon request from PonyEx certify in
              writing to PonyEx that such destruction has taken place.&nbsp;
            </p>
            <p>
              <strong>6.</strong>&nbsp; <strong>DISCLAIMER OF WARRANTIES.</strong> You acknowledge that the PonyEx App
              provided may contain bugs and errors. The PonyEx App is provided to you &quot;as is&quot; and any use of
              the PonyEx App is at your own risk. To the extent legally permitted under applicable law, PonyEx disclaims
              all warranties, whether express, implied or statutory, including without limitation, any implied
              warranties of title, non-infringement of third-party rights, merchantability or fitness for a particular
              purpose. Some jurisdictions do not allow the exclusion of implied warranties, so the above limitations may
              not apply to you.
            </p>
            <p>
              <strong>7.</strong>&nbsp; <strong>LIMITATION OF LIABILITY.</strong> You agree that to the extent permitted
              under the applicable law, PonyEx shall not be responsible for any loss or damage to you or others caused
              by failure of the PonyEx App to function. In no event will PonyEx be liable for any special,
              consequential, exemplary, incidental, or indirect damages (including, without limitation, those resulting
              from lost profits, cost of substitute goods or services, lost data or interruption of any mapping or
              communication functionality) in connection with the use of the PonyEx App or in connection with any other
              claim arising from this End-User License Agreement, even if PonyEx has been advised of the possibility of
              such damages. The aggregate liability of PonyEx arising from or relating to this End-User License
              Agreement and the downloading and use of the PonyEx App, regardless of the form of action or claim (e.g.,
              contract, warranty, tort, strict liability, negligence, fraud or other legal theory) is limited to the
              amounts paid by you to PonyEx during the six month period preceding the event giving rise to liability.
              Applicable law may not allow the exclusion or limitation of incidental or consequential damages, so the
              above limitation or exclusion may not apply to you.
            </p>
            <p>
              <strong>8. GENERAL PROVISIONS.</strong>
            </p>
            <p>
              <strong>9.1 Feedback.</strong> In the event that you provide PonyEx with comments, feedback or suggestions
              regarding the use, operation, improvement or functionality of the PonyEx App (
              <strong>&quot;Feedback&quot;</strong>), including but not limited to information about potential
              improvement, operating results, known or suspected bugs, errors or compatibility problems, or desired
              features, you hereby assign to PonyEx all rights in the Feedback and agree that PonyEx shall have the
              right to use the Feedback and related information in any manner it deems appropriate in its sole
              discretion.
            </p>
            <p>
              <strong>8.2 Governing Law, Arbitration and Venue.</strong> This End-User License Agreement shall be
              governed by the laws of the State of Utah without giving effect to any conflict of laws principles that
              may provide the application of the law of another jurisdiction.
            </p>
            <p>
              Disputes as to this End-User License Agreement, except those causing irreparable harm and requiring
              injunctive relief discussed below, shall be resolved using arbitration. &nbsp;The application of the
              United Nations Convention on the International Sale of Goods is hereby expressly excluded. Such
              arbitration shall be initiated through an established alternative dispute resolution provider (
              <strong>&quot;ADR Provider&quot;</strong>) that offers arbitration as set forth in this section and under
              the rules of such ADR Provider, except to the extent such rules are in conflict with this End-User License
              Agreement. The party demanding arbitration will propose an ADR Provider and the other party shall not
              unreasonably withhold consent to use such ADR Provider. The ADR Provider and the parties must comply with
              the following rules: a) the arbitration shall be conducted by telephone, online and/or be solely based on
              written submissions, the specific manner shall be chosen by the party initiating the arbitration; b) all
              arbitration proceedings shall be held in English; c) the arbitration shall not involve any personal
              appearance by the parties or witnesses unless otherwise mutually agreed by the parties; and d) any
              judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
              Each party shall bear its own costs (including attorney fees) and disbursements arising out of the
              arbitration, and shall pay an equal share of the fees and costs of the ADR Provider.
            </p>
            <p>
              You acknowledge that breach of this End-User License Agreement by you many cause irreparable injury to
              PonyEx for which monetary damages would not be an adequate remedy and, therefore, PonyEx will be entitled
              to seek injunctive relief (including specific performance) in a court of competent jurisdiction. &nbsp;To
              this end, you and PonyEx agree to submit to the jurisdiction of, and agree that venue is proper in, the
              state courts located in Utah and the federal courts located in the district in Utah where PonyEx has its
              principle place of business.
            </p>
            <p>
              <strong>8.3 Severability; Language.</strong> If any provision of this End-User License Agreement is found
              to be invalid or unenforceable, such provision will be deleted, changed and interpreted to accomplish the
              objectives of this End-User License Agreement to the greatest extent possible under applicable law and the
              remaining provisions will remain in full force and effect. The parties of this End-User License Agreement
              have expressly required that the present Agreement be drawn up in the English language.
            </p>
            <p>
              <strong>8.4 Waiver.</strong> Any waiver or failure to enforce any provision of this Agreement on one
              occasion will not be deemed a waiver of the same provision or any other provision on any other occasion.
            </p>
            <p>
              <strong>8.5 Entire Agreement.</strong> This End-User License Agreement and any terms whether express or
              implied included in the PonyEx App itself as to data gathering, advertising or in-App purchases
              constitutes the final, complete and exclusive agreement of the parties with respect to the subject matter
              hereof and supersedes and merges all prior discussions between the parties. No modification of or
              amendment to this End-User License Agreement, nor any waiver of any rights under this End-User License
              Agreement, will be effective unless in writing and signed by the party to be charged (which, in the case
              of the PonyEx, shall require the signature of a duly authorized officer of the PonyEx).
            </p>
            <p>
              <strong>8.6 Assignment. &nbsp;</strong>This Agreement is personal to you. &nbsp;You may not assign any
              right you may have under this Agreement to any person or entity except as may occur by operation of law.
              &nbsp;PonyEx may assign any of its ownership and rights to another without requiring any consent from you.
            </p>
            <p>
              <strong>TERMS OF USE</strong>
            </p>
            <p>
              <strong>1. WHAT THE PONYEX APP IS AND WHAT THE PONYEX APP IS NOT</strong>
            </p>
            <p>
              The PonyEx App is merely a communication platform. &nbsp;It provides any attendant conveniences of
              communication between Driver and Sender via-a-vis the pick-up, transportation and/or delivery of personal
              property/goods (&ldquo;Delivery Services.). &nbsp;The performance of Delivery Services by a Driver such as
              the time period in which the Delivery Services are performed by a Driver, as well as the item(s) of
              personal property/goods being picked-up, carried and/or delivered by a Driver as agreed by the Sender are
              collectively referred to as a &ldquo;Delivery&rdquo;. &nbsp;Neither PonyEx nor the PonyEx App provides
              Delivery Services or causes or participates in any Delivery. &nbsp;PonyEx provides communication services
              between Driver and Sender.
            </p>
            <p>
              The PonyEx App also facilitates payment and collection for Delivery Services. &nbsp;Because neither PonyEx
              nor the PonyEx App provides Delivery services, PonyEx does not earn payment for Delivery Services.
              &nbsp;PonyEx does, however, earn a fee for facilitating payment for Delivery Services (&ldquo;Collection
              Fee&rdquo;). &nbsp;The amount of any fee earned by PonyEx is part of the fee Driver charges Sender for a
              Delivery (&ldquo;Delivery Fee&rdquo;). &nbsp;PonyEx, via the PonyEx App, facilitates the purchase and
              collection of the Delivery Fee, receives the Delivery Fee, retains PonyEx&rsquo;s Collection Fee and
              forwards the balance of the Delivery Fee minus the Collection Fee to Driver. &nbsp;Details as to
              Collection Fees and Delivery Fees are discussed below.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                2. PONYEX IS NOT RESPONSIBLE FOR THE ACTIONS OF DRIVERS AND SENDERS COMMUNICATING VIA THE PONYEX APP
                AND/OR PROVIDING DELIVERY SERVICES
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              PonyEx is not an employer of Driver or Sender. &nbsp;You have no authority to bind PonyEx or cause PonyEx
              to have any responsibility for Delivery Services or for other delivery related legal obligations.
              &nbsp;Neither Driver nor Sender may undertake to hold yourself out as an employee, agent or authorized
              representative of PonyEx. Where, by implication of mandatory law or otherwise, you may be deemed an agent
              or representative of PonyEx, you agree to indemnify, defend (at PonyEx&rsquo;s option) and hold PonyEx
              harmless from and against any claims by any person, Driver or Sender related to the Delivery Services.
            </p>
            <p>&nbsp;</p>
            <p>
              You as a Driver or Sender expressly agree that: (a) the End-User License Agreement and/or these Terms of
              Use create an employment agreement with PonyEx or with each other; (b) no joint venture, partnership, or
              agency relationship exists between PonyEx and you; and (3) that except as otherwise provided in these
              Terms of Use PonyEx, Driver and Sender act as independent contractors to each other.
            </p>
            <p>&nbsp;</p>
            <p>
              Driver and Sender hereby acknowledge and agree that Driver is a self-employed/independent contractor who
              chooses to perform Delivery Services for Senders who post Delivery requests on the PonyEx App. At no time
              does PonyEx control whether or when a Sender posts, removes or retracts a particular Delivery request, or
              whether or when a Driver chooses to pursue and fulfill a Delivery request by offering Delivery Services
              for a particular Delivery request.&nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
              Communication between Driver and Sender through the PonyEx App regarding the performance of particular
              Delivery Services does not control whether a Driver chooses to simultaneously provide Delivery Services or
              any other types of services under another platform or App for Sender, another person or persons.
            </p>
            <p>&nbsp;</p>
            <p>
              Driver and Sender acknowledge and agree that by making or accepting a Delivery request, Driver and/or
              Sender may, outside the control of PonyEx, Driver and/or sender, be exposed to unknown third-parties and
              contents in deliveries that may be or are potentially dangerous, offensive, harmful, unsafe or otherwise
              objectionable and that PonyEx shall have no responsibility, obligation or liability therefor.
            </p>
            <p>&nbsp;</p>
            <p>
              If any State or federal law or regulation require you to be treated as an employee of PonyEx
              (&ldquo;Driver Employee Law&rdquo;), PonyEx reserves the right, in its sole discretion, to terminate this
              Agreement and prohibit use of the PonyEx App in whatever geographic location is governed by Driver
              Employee Law. &nbsp;If this Agreement is terminated due to a Driver Employee Law, you acknowledge and
              agree that PonyEx shall not be liable for any consequential or compensatory damages.
            </p>
            <p>&nbsp;</p>
            <p>
              As to any recipient of Items sent by Sender, neither the Limited End-User License Agreement nor the Terms
              of Use create or establish any third-party beneficiary rights in the recipient assertable against PonyEx
              or the PonyEx App. &nbsp;Any issue, claim or remedy a recipient may have as to delivery or the quality or
              status of a delivered Item is between the recipient and the Sender and/or Driver.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>
                3. PONYEX DOES NOT PROVIDE TRANSPORTATION OR LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION CARRIER
                OR A MOVING OR HAULING OF FREIGHT CARRIER
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              Because PonyEx does not provide Delivery Services, PonyEx does not provide any regulated transportation or
              logistics services. &nbsp;As a result, PonyEx has no control over and is not responsible for the
              performance, actions or inactions of any Driver or Sender, whether associated with the use of the PonyEx
              App or the receiving or providing the Delivery Services between Driver and Sender in public, private, or
              offline interactions, or otherwise.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>4. SAFEGUARDING PONYEX ACCESS AND SECURING THIRD PARTY INFORMATION</strong>
            </p>
            <p>
              You will secure and keep confidential your PonyEx App account password or any identification credentials
              we provide you which allows access to the PonyEx App and related services. &nbsp;You will not permit
              another person or entity to use your PonyEx App account password or any identification credentials we
              provide you which allows access to the PonyEx App and related services.
            </p>
            <p>
              You will secure and keep confidential any personal identifying information, confidential information, or
              information of any person, such as information governed by HIPPA, which you may receive through
              communications using the PonyEx App or which you may observe during the court of Delivery Services.
            </p>
            <p>
              <strong>5. PROPER/IMPROPER USES OF PONYEX</strong>
            </p>
            <p>
              You will not use the PonyEx App or Delivery Services for any unlawful purposes. &nbsp;You will not use the
              PonyEx App or Delivery Services for communicating, sending, transporting or storing any unlawful material
              or for deceptive or fraudulent purposes.&nbsp;
            </p>
            <p>
              The PonyEx App and Delivery Services will only be used in accordance with all applicable local, state,
              federal and international commercial and transportations laws and regulations as well as respecting
              copyrights, trade secrets, trademarks, patents or other rights of any third party, including privacy or
              publicity rights. &nbsp;Any material violation of these restrictions provides grounds for PonyEx to
              terminate your use of the PonyEx App.
            </p>
            <p>
              Driver and Sender agree that you each individually have an understanding of all transportation laws
              applicable to any Delivery request and/or Delivery Service in which you participate. &nbsp;You agree and
              acknowledge that you are not relying upon any communication, instruction, opinion or representation from
              PonyEx as to how to comply with transportation laws and regulations governing Delivery Services generally
              or any particular Delivery.
            </p>
            <p>In any event, you warrant and agree that as to Delivery Services and Delivery there shall be:</p>
            <p>(a) no transportation whatsoever of any person or animal;</p>
            <p>
              (b) no transportation of articles of exceptional value such as antiques, heirlooms, extravagant furniture,
              fine art, jewels/jewelry, gold or other precious metals &ndash; generally items that are valued at over
              $2,500 are considered exceptional value;
            </p>
            <p>
              &nbsp;(c) no transportation of hazardous materials of any kind, including, but not limited to explosives,
              gases, flammable liquids, flammable solids, and/or poisonous or infectious substances, pool chemicals,
              paints and solvents, or anything designated by the Department of Transportation as hazardous materials;
            </p>
            <p>(d) no transportation of firearms, ammunition or other explosive materials;</p>
            <p>(e) no transportation of unlawful drugs; and</p>
            <p>
              (f) no transportation of goods for which you have reason to believe are illegal or stolen
              (&ldquo;Prohibited Delivery&rdquo;).
            </p>
            <p>
              You acknowledge and agree that PonyEx is not responsible or liable for the shipment, preservation or
              disposal of contents of or any loss, fine or damage associated with any Prohibited Delivery known or
              unknown to PonyEx, Driver or Sender.
            </p>
            <p>
              Certain controlled substances, by way of example only but not limited to, such as cigarettes, other
              tobacco products, alcohol and unlawful drugs, may, dependent on governing state or federal law, only be
              handled, sold and distributed in accordance with such laws. &nbsp;Driver and Sender acknowledge to PonyEx
              and to each other that Driver and Sender have independently identified, understand and will comply with
              all state and federal laws governing the handling, sale and distribution of controlled substances.&nbsp;
            </p>
            <p>
              As a result, to prevent Prohibited Deliveries and to properly deliver other controlled substances, Sender
              and Driver agree to help protect each other through having Sender disclose generally the nature of the
              contents associated with its Delivery request and allowing the Driver to reserve its right to refuse
              Delivery Services based on a known or suspected Prohibited Delivery or delivery of other controlled
              substances.
            </p>
            <p>You will not contact another User after the delivery has been completed.</p>
            <p>
              You will not use any threatening, harassing, or otherwise inappropriate behavior to others using or
              associated with use of the PonyEx App.
            </p>
            <p>
              You will not use the PonyEx App to discriminate against another user in violation of any governing law or
              regulation.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>6. PROMOTIONS</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              Driver and Sender acknowledge and consent that PonyEx may from time to time offer promotions, promo codes
              and/or discounts for first time users or as a reward for referrals. These rewards will come in the form of
              credits that can be used when communicating about and/or paying for Delivery Services. &nbsp;The PonyEx
              App or any associated website may be adapted to obtain and redeem promotional credits, codes and/or
              discounts.
            </p>
            <p>&nbsp;</p>
            <p>
              PonyEx contemplates that a valid promotion credit, code or gift card must be entered before the service is
              requested in order for the credits to be applied to Delivery Services. &nbsp;Promotional credits, codes
              and/or discounts cannot be retroactively added to Delivery Services that have already been scheduled. Any
              promotional credits, codes and/or discounts earned using illegal or fraudulent methods will be null and
              void. &nbsp;PonyEx reserves the right to cancel or suspend promotional credits, codes and/or discounts
              prospectively.
            </p>
            <p>
              <strong>7. DRIVER AGREEMENT</strong>
            </p>
            <p>
              <strong>7.1 &nbsp;Driver Qualification and Provision of Delivery Services</strong>
            </p>
            <p>
              You, Driver, acknowledge and agree that at all times material to Delivery Services: (a) you shall hold and
              maintain (i) a valid driver&rsquo;s license with the appropriate level of certification to operate your
              Vehicle, and (ii) all licenses, permits, approvals and authority applicable to you that are necessary to
              provide Delivery Services to Sender between pick-up and delivery; (b) you are at least 18 years of age or
              at least the age required by law for the delivery of content identified by Sender; (c) you possess the
              appropriate and current level of training, expertise, experience and physical health to provide the
              Delivery Services required for the Delivery request in a professional and safe manner with due skill, care
              and diligence; (d) you will maintain high standards of professionalism, service and courtesy; (e) you will
              not provide Delivery Services when under the influence of alcohol, illegal drugs or any substance that
              impairs your ability to drive safely and interact with others; and (f) you are medically fit to drive and
              to lift at least 125 pounds without causing danger or health risk to yourself or another person. &nbsp;You
              represent that independent of PonyEx you have consulted all professionals necessary to ensure compliance
              with (a)-(f) above.
            </p>
            <p>
              You acknowledge and agree that you may be subject to certain background and driving record checks from
              time to time in order to qualify to provide, and remain eligible to provide the Delivery Services.
              &nbsp;You consent and allow PonyEx to request background and/or driving record records from relevant law
              enforcement or driver license agencies or departments. &nbsp;You acknowledge and agree that PonyEx
              reserves the right, at any time in PonyEx&rsquo;s sole discretion, to deactivate or otherwise restrict you
              from accessing or using the PonyEx App if you fail to meet the requirements set forth in these Agreements.
            </p>
            <p>
              Driver consent that PonyEx may independently verify your qualification documentation from time to time in
              any way PonyEx deems appropriate in its reasonable discretion.
            </p>
            <p>
              <strong>7.2 &nbsp;Vehicle Requirements</strong>
            </p>
            <p>
              You acknowledge and agree that any vehicle you use to provide Delivery Services (&ldquo;Vehicle&rdquo;)
              shall at all times be (a) properly registered and licensed to operate as a transportation vehicle in the
              state(s) corresponding the Delivery request; (b) suitable for performing the Delivery Services; (c)
              maintained in good operating condition, consistent with industry safety and maintenance standards for a
              Vehicle of its kind and any additional standards or requirements in the applicable state(s); and (d) in a
              clean and sanitary condition and meets reasonable appearance standards deemed acceptable to maintain the
              reputation of PonyEx and Sender.
            </p>
            <p>
              Driver consent that PonyEx may independently verify your Vehicle documentation from time to time in any
              way PonyEx deems appropriate in its reasonable discretion.
            </p>
            <p>
              <strong>7.3 &nbsp;Mobile Communications</strong>
            </p>
            <p>
              You agree that (a) you will possess an operable mobile device at all stages of Delivery Services; (b) use
              of the PonyEx App on your mobile device requires an active data plan with a wireless carrier associated
              with your mobile device, which data plan will be provided by you at your own expense; and (c) use of the
              PonyEx App on our mobile device as an interface with the Delivery Services may consume large amounts of
              data through the data plan.
            </p>
            <p>
              <strong>7.4 &nbsp;Insurance and Taxes</strong>
            </p>
            <p>
              After consulting with professionals, you have obtained and will maintain in force during all stages of
              Delivery Services, all automobile liability, workers&rsquo; compensation or health insurance coverage for
              Driver and your Vehicle.
            </p>
            <p>
              You agree that PonyEx is not responsible for paying for automobile liability, workers&rsquo; compensation
              or health insurance coverage for Driver and your Vehicle or for any liability that may arise from a
              Driver&rsquo;s performance of a Delivery Services, including any bodily injury or damage to property
              caused by or to the Driver while providing Delivery Services.
            </p>
            <p>
              Driver acknowledges and accepts responsibility for all financial consequences to you related to personal
              income tax and personal property tax liabilities incurred while providing Delivery Services.
            </p>
            <p>
              PonyEx has no responsibility or liability for any consequences resulting from Driver or Sender not having
              the proper authority, permits, licenses or insurance request or fulfill a Deliver request.
            </p>
            <p>
              <strong>7.5 &nbsp; Driver&rsquo;s Capability and Performance</strong>
            </p>
            <p>
              Because PonyEx cannot know whether a particular Driver is physically capable of successfully providing
              Delivery Services, it is entirely the responsibility of the Driver to know and abide by their own physical
              limits.
            </p>
            <p>
              For each and every Delivery Service you agree to: (a) as needed, properly distribute and secure the
              contents of a Delivery request (&ldquo;Cargo&rdquo;); (b) inspect the Cargo and devices to secure the
              Cargo prior to beginning trip; (c) have clear visibility on all sides of the Vehicle; and (d) use extreme
              caution and care with Sender&rsquo;s Cargo when place in or on the bed of truck-like Vehicle. &nbsp;
              Sender consents &nbsp;that Driver may note any existing damage to any item of Cargo and photograph it
              prior to transport.
            </p>
            <p>
              You agree to not accept a Delivery request for which you cannot safely load, securely transport and unload
              the intended Cargo with the Vehicle.
            </p>
            <p>
              You are responsible to mitigate or remediate damage to or loss of Cargo directly with the Sender. You
              agree that PonyEx shall have no liability for damage to or loss of Cargo at or between pick-up and
              delivery.
            </p>
            <p>
              <strong>7.6 &nbsp;Your Relationship with PonyEx</strong>
            </p>
            <p>
              You acknowledge and agree that access to the PonyEx App and participation in Delivery Services creates no
              transportation/delivery business relationship between PonyEx and you. PonyEx does not, and shall not be
              deemed to, direct or control you generally or in your particular performance of Delivery Services, your
              acts or omissions, your operation and maintenance of your Vehicle or your loading, transporting and/or
              unloading of Cargo. You retain the sole right to determine when, where, and for how long you will utilize
              the PonyEx App and comply with these Terms of Use, bearing all liability therefore.
            </p>
            <p>
              With the exception of any signage required by local law or permit/license requirements, PonyEx shall have
              no right to require you to: (a) display PonyEx&rsquo;s name, logos or colors on your Vehicle; or (b) wear
              a uniform or any other clothing displaying PonyEx&rsquo;s name, logos or colors.
            </p>
            <p>
              You acknowledge and agree that you have complete discretion to provide Delivery Services to fulfill a
              Delivery request or otherwise engage in other business or employment consistent with these Terms of Use.
            </p>
            <p>
              PonyEx retains the right to deactivate or otherwise restrict you from accessing or using the PonyEx App to
              communicate regarding the Delivery Services or from utilizing PonyEx&rsquo;s payment collection services
              in the event of a violation of this Agreement, your disparagement of PonyEx, your act or omission that
              causes harm to PonyEx&rsquo;s brand, reputation or business as determined by PonyEx in its sole
              discretion.
            </p>
            <p>
              <strong>7.7 &nbsp;Geo-Location Tracking Agreement</strong>
            </p>
            <p>
              To allow Sender and/or PonyEx to confirm the status and progress of Delivery Services provided by Driver,
              Driver agrees and consents that dynamic, geo-location information as to Driver&rsquo;s Vehicle will be
              continuously provided to Sender and PonyEx via Driver&rsquo;s mobile device and mobile communication
              service during the performance of Delivery Services. &nbsp;Driver hereby consents that the location of
              Driver&rsquo;s Vehicle used to provide Delivery Services may be electronically or visually monitored,
              tracked and shared for the benefit of Sender and PonyEx.
            </p>
            <p>
              <strong>7.8 &nbsp;Suspension and/or Permanent Termination of Driver Account</strong>
            </p>
            <p>
              <em>Driver Ratings. &nbsp;</em>The PonyEx App will allow Senders to rate and comment on your Delivery
              Service performance. &nbsp;In order to continue to receive access to the PonyEx App and participate in
              &nbsp;Delivery Services, you must maintain an average rating by Senders that exceeds the minimum
              acceptable rating established by PonyEx for your territory of service, as may be updated from time to time
              by PonyEx in its sole discretion (&ldquo;Minimum Average Rating&rdquo;). Your average rating will be
              determined by Sender&rsquo;s satisfaction with your Delivery Services, not by your technical compliance
              with the End-User License Agreement and these Terms of Use. &nbsp;In the event your average rating falls
              below the Minimum Average Rating, PonyEx will notify you and may provide you, in PonyEx&rsquo;s
              discretion, a limited period of time to raise your average rating above the Minimum Average Rating. If you
              do not increase your average rating above the Minimum Average Rating within the time period allowed (if
              any), PonyEx reserves the right to suspend or permanently terminate/deactivate your access to the PonyEx
              App and your participation in Delivery Services.
            </p>
            <p>
              <em>Display of Driver Ratings.&nbsp;</em> Driver agrees and consents that PonyEx may use, share and
              display the ratings and comments of Sender&rsquo;s vis-&agrave;-vis your Delivery Service performance on
              the PonyEx App or related website in any manner in connection with the business of PonyEx without
              attribution to you or your approval. You acknowledge and agree that PonyEx is a conduit of (without any
              obligation to verify) and not the author or publisher of Sender ratings and comments, provided that PonyEx
              reserves the right to edit or remove comments in the event that such comments include obscenities or other
              objectionable or false content, include an individual&rsquo;s name or other personal information, or
              violate any privacy laws, or other applicable laws or these Terms of Use.
            </p>
            <p>
              <strong>7.9 &nbsp;Reimbursement of Out-of-Pocket of Expense Paid by Driver</strong>
            </p>
            <p>
              Driver will only be reimbursed for out-of-pocket costs and expenses incurred in performing and directly
              related to a Delivery. &nbsp;Reimbursement requires Driver to present PonyEx with adequate documentary
              evidence of incurring the same (a receipt) within fifteen (15) days of incurring the expense. Such
              expenses include fees, permits, taxes and tolls directly related to a particular Delivery, but do not
              include Vehicle fuel, service, maintenance, insurance and the like related to generally operating the
              Vehicle.
            </p>
            <p>
              <strong>7.10 &nbsp;Exchange of User and Destination Information</strong>
            </p>
            <p>
              If you accept a posted Delivery request for Delivery Services, the PonyEx App will provide you with
              certain Sender Information including the Sender&rsquo;s name, pick-up and Delivery destination
              &nbsp;locations and other pick-up contact information. Driver shall give Sender or Sender&rsquo;s agent at
              least ten (10) minutes beyond the scheduled pick-up time to appear to coordinate pick-up of Cargo at the
              indicated location.
            </p>
            <p>You will obtain the Delivery destination from the Sender through the PonyEx App.</p>
            <p>
              You acknowledge and agree that once you have accepted a Sender&rsquo;s request for Delivery Services, the
              PonyEx App may provide certain information about you to the Sender including at least your first name,
              contact information, photo, pick-up location, and your Vehicle&rsquo;s make, model and license plate
              number to permit the Sender or Sender&rsquo;s agent to confirm Driver authenticity.
            </p>
            <p>
              Except for purposes associated with the particular, accepted Delivery request, you shall not contact any
              Sender or Receiver (the person or entity receiving delivery) or use any personal data or contact
              information of Sender or Receiver for any other reason including for a future delivery. &nbsp;As agreed
              below with respect to non-disclosure, non-competition and non-circumvention, you will make no arrangement
              for other or future Delivery Services to be provided by yourself or by another with any Sender or Receiver
              except through the PonyEx App or related website.&nbsp;
            </p>
            <p>
              Independent of PonyEx, you acknowledge and agree that: (a) you shall be solely responsible for determining
              the most effective, efficient and safe manner to perform each instance of Delivery Services; and (b) you
              shall provide all necessary licensed and registered Vehicles, Vehicle accessories, equipment, tools and
              other materials, at your own expense, necessary to perform the requested Delivery Services.
            </p>
            <p>
              <strong>7.11 &nbsp;Financial Terms</strong>
            </p>
            <p>
              <em>Delivery Calculation and Your Payment.</em> You are entitled to charge a fare for each completed
              Delivery Service provided to a Sender based upon the Delivery request identified by the Sender and
              consistent with PonyEx&rsquo;s size and weight designations for each Delivery, see sections 8.1 and 8.2
              below (&ldquo;Fare&rsquo;&rsquo;), calculated based on current standards for the transportation area and
              established by the PonyEx that may include minimum charge, base rate, per mile charge, and user discounts
              or promo codes (&ldquo;Fare Calculation&rdquo;). Notwithstanding the foregoing, there may be situations
              where the Fare is predetermined, <em>e.g.,&nbsp;</em>fixed rates or minimum Fare. You acknowledge and
              agree that the Fare less a Service Fee is the payment you will receive in connection with the execution of
              Delivery Services. You acknowledge that PonyEx may distribute user discounts (promo codes) to encourage
              usage of the PonyEx App and that the Fare available to you will be less than the normal Fare when a promo
              code is utilized by a Sender.
            </p>
            <p>
              If you are required or requested to wait longer than twenty-minutes due to Sender&rsquo;s delay, you may
              charge Sender and Sender will pay an additional wait fee of $5 per fifteen minutes (&ldquo;Wait
              Fee&rdquo;).
            </p>
            <p>
              You are entitled to charge User for any tolls, taxes or fees incurred during the execution of Delivery
              Services, if applicable.
            </p>
            <p>
              You (a) appoint PonyEx as your limited payment collection agent solely for the purpose of accepting the a
              delivery fare, tips, applicable tolls and, depending on the region and/or if requested by you, applicable
              taxes and fees from the Sender on your behalf via the payment processing functionality facilitated by the
              PonyEx App; and (b) agree that payment made by Sender through PonyEx shall be considered the same as
              payment made directly by Sender to you.
            </p>
            <p>
              <em>Changes to Fare Calculation; Fare Adjustment.</em> PonyEx reserves the right to change the amount
              charged for Delivery Service (&ldquo;Fare&rdquo;) at any time in PonyEx&rsquo;s discretion based upon
              local market factors. Your acceptance of a Delivery request with the associated driver earnings will
              constitute your acceptance of the PonyEx&rsquo;s Fare calculation. Additionally, PonyEx reserves the right
              to cancel the Fare for a particular instance of Delivery Services (e.g., User is charged for Delivery
              Services that were not provided, in the event of a Sender complaint, fraud, etc.). PonyEx&rsquo;s decision
              to alter, reduce or cancel the Fare in any such manner shall be exercised in a reasonable manner.
            </p>
            <p>
              <em>Service Fee</em>. In consideration of PonyEx helping drivers find senders and senders find drivers and
              of payment processing associated with Delivery Services for your use and benefit hereunder, you agree to
              pay PonyEx a service fee on a per Delivery Services transaction basis (&ldquo;Service Fee&rdquo;).
              &nbsp;The Service Fee will include, at a minimum, a finder&rsquo;s fees for provisioning of Delivery
              Services (&ldquo;Finder&rsquo;s Fee&rdquo;) and fees for processing Sender payments (&rdquo;Payment
              Processing Fee&rdquo;). &nbsp;Any tips you receive from a Sender or Receiver whether in cash or through
              the PonyEx&rsquo;s payment processing will not be subject to the PonyEx Finder&rsquo;s Fee. Tips submitted
              through the PonyEx&apos;s payment processing are subject to the Payment Processing Fee. In the event
              government laws for regulations applicable to your delivery area require taxes to be calculated on the
              Fare, PonyEx shall calculate the Service Fee based on the Fare net of such taxes. PonyEx reserves the
              right to change the Service Fee at any time in PonyEx&rsquo;s discretion based upon local market factors.
              Your acceptance of a Delivery request with the associated driver earnings will constitute your acceptance
              of the Service Fee.
            </p>
            <p>
              <em>Service Fee Reduction Policy</em>. &nbsp;PonyEx May reduce its Service Fee based upon you achieving
              certain factors through the PonyEx App which will be set by PonyEx. Factors that may be included in the
              Service Fee reduction bonus are: average star ratings, number of positive reviews, referrals, punctuality,
              completed deliveries and number of times you have been favorited. PonyEx reserves the right to change the
              calculation of the Service Fee reduction bonus at any time.
            </p>
            <p>
              <em>Payment Practices</em>. PonyEx will process the Sender payment on your behalf through a third-party
              payment processing functionality. We will use commercially reasonable efforts to remit amounts owed to you
              at least once a week. In the event that a Sender payment is not honored or is otherwise unsuccessful (bank
              card declined), PonyEx will make best effort in reaching the Sender to resolve the payment issue and
              PonyEx will then remit your payment as soon as the Sender makes a successful, follow up payment.
            </p>
            <p>
              <em>Cancellation Charges</em>. You acknowledge and agree that Sender may elect at any time to cancel a
              Delivery Service request that has already been accepted by you via the PonyEx App. In the event that a
              Sender cancels an accepted request for Delivery Services, PonyEx may charge the Sender a cancellation fee
              on your behalf (&ldquo;Cancellation Fee&rdquo;). If charged, the Cancellation Fee shall be deemed the Fare
              for the cancelled Delivery Services for the purpose of remittance to you hereunder.
            </p>
            <p>
              <em>Taxes</em>. You acknowledge and agree that you are required to: (a) complete all tax registration
              obligations and calculate and remit all tax liabilities related to your provision of Delivery Services,
              including all Service Fees paid to you, as required by applicable law; and (b) provide PonyEx with all
              relevant tax information if and as requested by PonyEx. You further acknowledge and agree that you are
              responsible for taxes on your own income arising from the performance of Delivery Services.
            </p>
            <p>
              <strong>8. SENDER AGREEMENT</strong>
            </p>
            <p>
              8.1 &nbsp;<strong>Responsibilities of Sender regarding Delivery Requests</strong>
            </p>
            <p>
              In order to request a Delivery, you, the Sender, will have to specify certain information about the
              Delivery request which may include the pick-up address, the name of the recipient and destination address,
              the size of delivery (Pony, PonyEX, PonyXL, or PonyExpress), a description of the items you are requesting
              to be delivered (&ldquo;Items&rdquo;), the contact information of you and the recipient, the date and time
              of pick-up and the acceptable time allowance between pick-up and delivery subject to unforeseen or
              delivery obstacles outside the control of Driver such as weather, traffic, delivery vehicle breakdown,
              illness and/or recipient availability.
            </p>
            <p>
              Using the address you provide, PonyEx will use commercially reasonable efforts to find you with a Driver
              to perform the Delivery request for you and will provide the Driver applicable details regarding the
              Delivery request. If no Drivers are available, PonyEx will notify you that there are currently no Drivers
              available to perform the Delivery request, at which point PonyEx &nbsp;will have no further obligation to
              attempt to find you a Driver for the applicable Delivery request.
            </p>
            <p>
              For Pony and PonyEX size Delivery requests, upon pickup, Sender is responsible for bringing Item(s) to a
              specified exterior door or garage of Sender. &nbsp;Upon Delivery, Driver will deliver Item(s) to a
              designated front door, garage, or specified exterior door of Recipient.
            </p>
            <p>
              For PonyXL and PonyExpress size Delivery requests, upon pick-up Sender is responsible for loading the
              Item(s). &nbsp;Upon Delivery, the Sender or Recipient is responsible for unloading the Item(s) at the
              delivery destination. Driver may help upon Driver&rsquo;s own discretion. Upon booking the delivery,
              Sender may request, for an additional fee, a second vehicle and Driver to assist in the delivery and/or
              request Driver assistance in loading and unloading the Item(s). Sender &nbsp;will still be responsible to
              provide the additional manpower required to load or unload any items a delivery driver could reasonably
              not do alone.
            </p>
            <p>
              At no point between pick-up and delivery will Sender be permitted to ride in Driver&rsquo;s Vehicle with
              the Driver unless the Driver is the Sender.
            </p>
            <p>
              If Driver must wait for longer than twenty-minutes beyond the scheduled pickup time due to your delay,
              Driver may charge you and you will pay an additional Wait Fee (&ldquo;Wait Fee&rdquo;) every 15 minutes
              past the initial twenty minute scheduled pickup time.
            </p>
            <p>
              <strong>8.2 &nbsp;Financial Terms</strong>
            </p>
            <p>
              <em>Calculation of Fees. &nbsp;</em>You will be charged a Fare for each completed Delivery Service
              provided to you by a Driver, calculated based upon Delivery type fee for Pony, PonyEX, PonyXL and/or
              PonyExpress delivery designation which contemplates the size and/or weight of the Item(s) to be delivered,
              vehicle size, mileage, additional fees (urgency, weight, extra person fee) plus any applicable tolls and
              taxes, less any promo codes or credits available to you. &nbsp;A minimum Fare designated by PonyEx will
              apply regardless of promo codes or credits used (&ldquo;Minimum Fare&rdquo;). &nbsp;In addition, you will
              be required to pay the Wait Fee identified in section 7.11 for a delay in pick-up.
            </p>
            <p>
              <em>Payment for Delivery Services.&nbsp;</em>Payment of the Fare shall be made through the payment
              processing made available through the PonyEx App and shall be considered the same as payment made directly
              by you to the Driver. &nbsp;Fare(s) paid by you are final and non-refundable, unless otherwise determined
              by PonyEx.
            </p>
            <p>
              Unless otherwise agreed, PonyEx accepts identified credit card and Paypal through its third party payment
              processor. Upon scheduling a Delivery, payment will be required in advance. Your payment includes all
              final Fares, fees, taxes, and gratuity, if desired, which will be combined for a total amount to be
              charged to you for the Delivery Services. If the delivery is unable to be completed due to the driver or
              PonyEx, PonyEx will refund your full payment to Sender. &nbsp;Further, Sender will be refunded if properly
              cancelled within the accepted cancellation period as outlined in Section 8.6.
            </p>
            <p>
              <em>Tip or Gratuity</em>. &nbsp;PonyEx&rsquo;s Fares do not designate any portion of the stated PonyEx
              Fare as a tip or gratuity to the Delivery Provider. &nbsp;Gratuities are voluntary and additional.
            </p>
            <p>
              <em>Delivery Confirmation</em>. &nbsp;At the Delivery destination, Driver shall confirm delivery and
              Sender will receive via SMS Text or email, as requested, a Delivery confirmation including a photo of the
              delivered Item(s) (&ldquo;Delivery Confirmation&rdquo;).
            </p>
            <p>
              After receiving the Delivery Confirmation, Sender will be prompted to leave a review and will be given the
              option to &ldquo;Favorite&rdquo; the Driver.
            </p>
            <p>
              Senders may have the option of requesting a &ldquo;Favorited&rdquo; Driver when booking future Delivery
              requests. Company will attempt to accommodate Sender&rsquo;s request for a Favorited Driver, but cannot
              and does not guarantee that a Favorited Driver will be available.
            </p>
            <p>
              <strong>8.3 &nbsp;Undeliverable Items</strong>
            </p>
            <p>
              Sender shall be assessed a $500 fee if the Delivery Services are timely provided as agreed but due to the
              size, weight or nature of the Item or due to the unavailability of the Recipient the Item cannot be
              delivered as agreed at the requested Delivery location/destination, unless the Sender or Recipient does
              within one hour provide the necessary, albeit delayed, delivery measures called for by the Delivery
              request. &nbsp;In addition, when delivery is delayed as set forth in this section the Wait Fee defined
              above shall be charged to the Sender.
            </p>
            <p>
              <strong>8.4 &nbsp;Confirmation of Driver Identification and Capability</strong>
            </p>
            <p>
              Upon pick-up, it is Sender&rsquo;s responsibility to confirm the Driver&rsquo;s identification to
              Sender&rsquo;s satisfaction. &nbsp;This can be done by matching the Driver&rsquo;s identification with the
              information provided by the PonyEx App prior to placing the Item(s) with Driver for delivery.
            </p>
            <p>
              Upon pick-up, it is Sender&rsquo;s responsibility to independently assess the adequacy of Driver&rsquo;s
              delivery capability by observation of the Driver and the Driver&rsquo;s Vehicle. &nbsp;Upon pick-up and
              placing Item(s) in the possession of Driver, Sender acknowledges the adequacy of Driver&rsquo;s and
              Vehicle&rsquo;s delivery capability.
            </p>
            <p>
              <strong>8.5 &nbsp;Sender Responsibilities regarding Items for Delivery</strong>
            </p>
            <p>
              Sender should leave any package or exemplary package containing an Item for delivery open for inspection
              by Driver. &nbsp;If a Sender doesn&rsquo;t leave the package open, Driver always has the right to require
              Sender to open the package or exemplary package to inspect Item(s) before Driver accepts the package(s)
              for delivery. &nbsp;As to new-in-box Item(s) in a package of a third party or baggage of third-parties,
              &nbsp;Driver has the responsibility to inspect the outside of the package or baggage to make
              Driver&rsquo;s independent decision to accept the package for delivery. &nbsp;Upon acceptance of the
              package or baggage for delivery by Driver, Sender&rsquo;s obligation of disclosure as to the Item(s) for
              delivery has been met.
            </p>
            <p>
              Upon pick-up, Driver shall note and memorialize by written memo or photograph any damage to the Item(s),
              packages or baggage before accepting the Item for delivery. Any damage so noted or memorialized, shall be
              the responsibility of Sender and no Driver.
            </p>
            <p>
              In all cases, Sender has the responsibility to package items appropriately based on nature of the Item,
              level of transportation risk, fragility, other factors specific to the individual Item, the Vehicle
              offered by Driver, to ensure the safety and integrity of the Item or compliance with all applicable
              laws.&nbsp;
            </p>
            <p>
              In no case shall PonyEx be liable for loss or damage to any Item associated with a Delivery request.
              &nbsp;PonyEx Inc. has no and will have no responsibility to protect Sender&rsquo;s Item(s) under PonyEx
              Protection Plan for any loss of, or damage to, any Item prepared by Sender for delivery.
            </p>
            <p>
              <strong>8.6 &nbsp;Cancellation Policy</strong>
            </p>
            <p>
              PonyEx will charge Sender and process payment by Sender for a Cancellation Fee published on the PonyEx App
              for each late-cancelled Delivery request and/or for each no-show at pick-up. &nbsp;Sender may request a
              cancellation of a Delivery any time before the Delivery request has been accepted by Driver. A Delivery
              request is deemed late-cancelled when the Sender cancels the Delivery request after Driver has accepted
              the Delivery request (&ldquo;Late-Cancelled Delivery Request&ldquo;).&nbsp;
            </p>
            <p>
              A pick-up is deemed a no-show when, subject to delay and a Wait Fee described above, &nbsp;you present
              &nbsp;Item(s) for pick-up more than fifteen (15) minutes after the agreed pick-up/drop-off time without
              calling, messaging or otherwise contacting the Driver through the PonyEx App, (ii) present &nbsp;Item(s)
              for pick-up more than 30 minutes after the agreed pick- up/drop-off time, if you have contacted the Driver
              or (iii) if you ultimately fail to present Item(s) for pick-up by Driver at the requested time and place
              as designated in the Delivery request (&ldquo;No-Show&ldquo;).&nbsp;
            </p>
            <p>
              In the case of a no-show, the Driver will report a No-Show through the PonyEx App. &nbsp;A No-Show will
              cause the existing Delivery request to be cancelled.
            </p>
            <p>
              The Cancellation Fee for Late-Cancelled Delivery Requests will be $10 for a designated Pony or PonyEx size
              Delivery request or $20 for a designated PonyXL or PonyExpress Delivery request. PonyEx reserves the right
              to change the published Cancellation Fee from time to time.
            </p>
            <p>
              &nbsp;The Cancellation fee will be retained by PonyEx and Driver &nbsp;from the amount collected upon
              making the Delivery request.
            </p>
            <p>
              PonyEx may temporarily suspend your account after your second Late-Cancelled Delivery Request or second No
              Show and may permanently terminate your account upon your third Late-Cancelled Delivery Request or
              No-Show.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>9. PONYEX PROTECTION PLAN</strong>
            </p>
            <p>
              <strong>9.1 Limited Protection</strong>
            </p>
            <p>
              Any recovery from PonyEx for damage, loss or theft to any Item is limited under the PonyEx Protection
              &nbsp;Plan. &nbsp;PonyEx insures each Item against damage, loss or theft up to $100 per Delivery.&nbsp;
            </p>
            <p>
              In no case shall PonyEx&rsquo;s limited liability to Sender for loss, damage or theft to any Item during
              Delivery exceed the Item&rsquo;s actual, documented costs to repair or replace the Items.
            </p>
            <p>
              <strong>9.2 Filing a Claim</strong>
            </p>
            <p>
              Sender shall have forty-eight (48) hours after delivery to file a claim
              with&nbsp;support@ponyex.com&nbsp;for claims valued at $100 and less. If additional insurance has been
              purchased, please refer to the third party insurance company for claim submissions. &nbsp;Sender waives
              any claim not submitted timely.
            </p>
            <p>
              <strong>
                10. DRIVER OR SENDER ACTIONS LEADING TO TEMPORARY SUSPENSION AND/OR PERMANENT TERMINATION OF ACCOUNTS
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              Notwithstanding any of the forgoing, and without limiting any other of its remedies at law or in equity,
              PonyEx may temporarily suspend and/or permanently terminate any Driver or Sender PonyEx account and access
              to the PonyEx App and any participation in Delivery Services if:
            </p>
            <p>&nbsp;</p>
            <p>
              you breach, are suspected of breaching, or are alleged to have breached these Terms or the terms or Driver
              or Sender Agreement to which you are subject or any of PonyEx&rsquo;s other terms or policies governing a
              use PonyEx App;
            </p>
            <p>&nbsp;</p>
            <p>PonyEx is unable to verify or authenticate any information you provide to us;</p>
            <p>&nbsp;</p>
            <p>
              PonyEx believes that your actions may cause financial loss or legal liability for you, our Users or
              PonyEx, its affiliates, or third party providers;
            </p>
            <p>&nbsp;</p>
            <p>
              PonyEx has a good faith basis to believe you may subject PonyEx or you or any other User to employment or
              transportation regulation by any state or local government or regulatory agency;
            </p>
            <p>&nbsp;</p>
            <p>
              we suspect or it has been alleged that you have engaged in fraudulent, illegal or harmful activity in
              connection with your use or access of the PonyEx App, or in connection with your receiving or providing
              Delivery Services or you or your account is the subject of an investigation of alleged or suspected
              fraudulent, illegal or harmful activity; or
            </p>
            <p>&nbsp;</p>
            <p>prohibited Items are discovered in your Delivery.</p>
            <p>&nbsp;</p>
            <p>
              PonyEx may temporarily suspend a User&rsquo;s account while investigating any incident or action that
              could lead to PonyEx permanently terminating your account and/or access to the PonyEx App. &nbsp;Your
              account may be suspended for such time as PonyEx determines in its sole discretion. &nbsp;You may provide
              PonyEx notice in writing of any extenuating circumstances surrounding the events leading to the suspension
              and/or termination which PonyEx may consider in its sole and absolute discretion when making decisions
              regarding the status of your account and access to the PonyEx App.
            </p>
            <p>
              <strong>11. NON-DISCLOSURE, NON-COMPETE, NON-CIRCUMVENTION AND NON-SOLICITATION</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>11.1 Proprietary Information.</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              You acknowledge and agree that PonyEx has developed and owns the PonyEx App which finds and connects
              Driver and Sender who, but for the PonyEx App, would not know about each other or would not otherwise be
              able to identify how Driver and Sender may interact for Deliver Services.&nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
              That is, the identity of Sender and each first and subsequent Delivery request by a Sender and the
              identity of Driver and each first and subsequent Delivery Service provided by Driver constitute trade
              secret information of PonyEx.
            </p>
            <p>&nbsp;</p>
            <p>
              In exchange for this finding service and the connection and introduction of Driver to Sender and of Sender
              to Driver, you agree that PonyEx is appointed as your payment collection agent for all deliveries
              requested by Sender and all delivery services provided by Driver occurring after the first introduction of
              Driver and Seller via the PonyEx App.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>11.2 Non-Disclosure Agreement</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              In order the maintain the proprietary and confidential nature of the identity of Sender and Driver, of the
              Delivery requests of Sender and of the Delivery Services of Driver, each Driver and Sender agree as
              follows:
            </p>
            <p>&nbsp;</p>
            <p>
              Driver will not disclose any Delivery request of a Sender to any other person or entity unassociated with
              PonyEx or the PonyEx App who are not bound by all of the same obligations as Driver under these
              Agreements.
            </p>
            <p>&nbsp;</p>
            <p>
              Sender will not disclose any Delivery Service of a Driver to any other person or entity unassociated with
              PonyEx or the PonyEx App who are not bound by all of the same obligations as Sender under these
              Agreements.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>11.3 Non-Use and Non-Compete Agreement</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              Driver agrees not to use or compete with PonyEx&rsquo;s service of finding Delivery requests including the
              use of any Sender&rsquo;s identity, location or business identified using the PonyEx App for any Delivery
              subsequent to the first Delivery involving Driver and Sender unless the subsequent Delivery is facilitated
              using the PonyEx App.
            </p>
            <p>&nbsp;</p>
            <p>
              Sender agrees not to use or compete with PonyEx&rsquo;s service of finding Delivery Services through the
              PonyEx including the use of any Driver&rsquo;s identify or Vehicle identified using the PonyEx App for any
              Delivery subsequent to the first Delivery involving Driver and Sender unless the subsequent Delivery is
              facilitated using the PonyEx App.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>11.4 Non-Circumvention Agreement</strong>.
            </p>
            <p>&nbsp;</p>
            <p>
              Driver and Sender agree not to circumvent the payment collection service of PonyEx by (i) dealing directly
              with each other outside of the PonyEx App subsequent to any Delivery involving Driver and Sender resulting
              from use of the PonyEx App or (ii) altering the Delivery request or Items to be delivered among themselves
              independent of PonyEx or the PonyEx App. &nbsp;Driver agrees to refrain from providing any Delivery
              Services directly or indirectly with any Sender or simultaneously with the same Sender or entity or person
              related to or associated with Sender unless Driver does so through the PonyEx App. &nbsp;Sender agrees to
              refrain from making any Delivery request directly or indirectly with any Driver or simultaneously with the
              same Driver or entity or person related to or associated with Driver unless Sender does so through the
              PonyEx App.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>11.5 Non-Solicitation</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              Driver agrees that Driver will not solicit any delivery request from a Sender first identified to Driver
              directly through the PonyEx App or indirectly by another Driver or Send having or having had access to
              PonyEx App unless that solicitation occurs using the PonyEx App to accept the delivery request.
            </p>
            <p>&nbsp;</p>
            <p>
              Sender agrees that Sender will not solicit any delivery services from a Driver first identified to Sender
              directly through the PonyEx App or indirectly by another Driver or Send having or having had access to
              PonyEx App unless that solicitation occurs using the PonyEx App to seek the delivery services.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>11.6 Duration of Agreements</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              Driver&rsquo;s and Sender&rsquo;s obligations under this section 11 shall be effective throughout the
              duration of your use of the PonyEx App and for a period of three years from the date of the last Delivery
              involving Driver or Seller under these Terms of Use.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>11.7 Destruction of Driver/Seller Information</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              Upon termination of either the limited End-User License or your use of the PonyEx App under the Terms of
              Use, any Driver or Sender contact information obtained directly or indirectly through the PonyEx App or
              directly from a Driver or Sender shall be destroyed, that is, all printed documents and/or electronic
              files and copies thereof, or any other written documentation or tangible things regarding the a Delivery
              between Driver and Sender shall be permanently destroyed or deleted and not again accessed by Driver or
              Sender.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>11.8</strong> <strong>Enforcement</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              In the event it becomes necessary for PonyEx to enforce the provision of section 11, Driver and Sender
              agree to subject to the ADR provisions of section 12.
            </p>
            <p>
              <strong>12. &nbsp; &nbsp;GENERAL PROVISIONS.</strong>
            </p>
            <p>
              <strong>12.1 Governing Law, Arbitration and Venue.</strong> This End-User License Agreement shall be
              governed by the laws of the State of Utah without giving effect to any conflict of laws principles that
              may provide the application of the law of another jurisdiction.
            </p>
            <p>
              Disputes as to this End-User License Agreement, except those causing irreparable harm and requiring
              injunctive relief discussed below, shall be resolved using arbitration. &nbsp;The application of the
              United Nations Convention on the International Sale of Goods is hereby expressly excluded. Such
              arbitration shall be initiated through an established alternative dispute resolution provider (
              <strong>&quot;ADR Provider&quot;</strong>) that offers arbitration as set forth in this section and under
              the rules of such ADR Provider, except to the extent such rules are in conflict with this End-User License
              Agreement. The party demanding arbitration will propose an ADR Provider and the other party shall not
              unreasonably withhold consent to use such ADR Provider. The ADR Provider and the parties must comply with
              the following rules: a) the arbitration shall be conducted by telephone, online and/or be solely based on
              written submissions, the specific manner shall be chosen by the party initiating the arbitration; b) all
              arbitration proceedings shall be held in English; c) the arbitration shall not involve any personal
              appearance by the parties or witnesses unless otherwise mutually agreed by the parties; and d) any
              judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
              Each party shall bear its own costs (including attorney fees) and disbursements arising out of the
              arbitration, and shall pay an equal share of the fees and costs of the ADR Provider.
            </p>
            <p>
              You acknowledge that breach of this End-User License Agreement by you many cause irreparable injury to
              PonyEx for which monetary damages would not be an adequate remedy and, therefore, PonyEx will be entitled
              to seek injunctive relief (including specific performance) in a court of competent jurisdiction. &nbsp;To
              this end, you and PonyEx agree to submit to the jurisdiction of, and agree that venue is proper in, the
              state courts located in Utah and the federal courts located in the district in Utah where PonyEx has its
              principle place of business.
            </p>
            <p>
              <strong>12.3 Severability; Language.</strong> If any provision of this End-User License Agreement is found
              to be invalid or unenforceable, such provision will be deleted, changed and interpreted to accomplish the
              objectives of this End-User License Agreement to the greatest extent possible under applicable law and the
              remaining provisions will remain in full force and effect. The parties of this End-User License Agreement
              have expressly required that the present Agreement be drawn up in the English language.
            </p>
            <p>
              <strong>12.4 Waiver.</strong> Any waiver or failure to enforce any provision of this Agreement on one
              occasion will not be deemed a waiver of the same provision or any other provision on any other occasion.
            </p>
            <p>
              <strong>12.5 Entire Agreement.</strong> This End-User License Agreement and any terms whether express or
              implied included in the PonyEx App itself as to data gathering, advertising or in-App purchases
              constitutes the final, complete and exclusive agreement of the parties with respect to the subject matter
              hereof and supersedes and merges all prior discussions between the parties. No modification of or
              amendment to this End-User License Agreement, nor any waiver of any rights under this End-User License
              Agreement, will be effective unless in writing and signed by the party to be charged (which, in the case
              of the PonyEx, shall require the signature of a duly authorized officer of the PonyEx).
            </p>
            <p>
              <strong>12.6 Assignment. &nbsp;</strong>This Agreement is personal to you. &nbsp;You may not assign any
              right you may have under this Agreement to any person or entity except as may occur by operation of law.
              &nbsp;PonyEx may assign any of its ownership and rights to another without requiring any consent from you.
            </p>
            <p>
              <strong>12. &nbsp; &nbsp;PRIVACY</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              PonyEx shall maintain the privacy of all data of Driver and Sender provided to PonyEx unless disclosure is
              required by the functionality of the PonyEx App or by applicable law. &nbsp;Driver and Sender shall
              maintain the privacy of all data of Driver and Sender received using the PonyEx App unless disclosure is
              required by the functionality of the PonyEx App or by applicable law.&nbsp;
            </p>
          </div>

          <FormItem error={formState.errors.terms?.message || ''}>
            <Checkbox id="terms" label="I agree to PonyEx's Terms." {...register('terms')} required />
          </FormItem>
        </div>
      </div>
    </>
  );
};
