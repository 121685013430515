import { useMemo } from 'react';

export const Divider = ({ title, titlePosition = 'left' }: Props) => {
  const titleClassName = useMemo(() => {
    switch (titlePosition) {
      case 'left':
        return 'justify-start';
      case 'right':
        return 'justify-end';
      case 'center':
        return 'justify-center';
    }
  }, [titlePosition]);

  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className={`relative flex ${titleClassName}`}>
        {title && <span className="pr-3 bg-white text-lg font-medium text-gray-900">{title}</span>}
      </div>
    </div>
  );
};

type Props = {
  title?: string;
  titlePosition?: 'left' | 'right' | 'center';
};
