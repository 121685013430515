import { forwardRef } from 'react';
import { Control, useController } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { CalendarIcon, XIcon } from '@heroicons/react/solid';

import { Input } from '../Input';

export const DatePicker = ({ name, control, placeholder, useTime, error }: Props) => {
  const { field } = useController({
    control,
    name,
  });

  const handleDateChange = (date: Date | null) => {
    field.onChange(date);
  };

  const clearInput = () => {
    field.onChange(null);
  };

  const DatePickerInput = forwardRef<HTMLInputElement, any>(function DatePickerInput({ value, onClick }, ref) {
    return (
      <div className="relative flex items-stretch flex-grow focus-within:z-10">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <Input
          value={value}
          className="pl-10 cursor-pointer"
          onClick={onClick}
          ref={ref}
          readOnly
          placeholder={placeholder}
          error={error}
        />
        {!!field.value && (
          <div className="absolute inset-y-0 right-0 px-3 flex items-center cursor-pointer" onClick={clearInput}>
            <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
      </div>
    );
  });

  return (
    <ReactDatePicker
      onChange={handleDateChange}
      selected={field.value}
      customInput={<DatePickerInput />}
      showTimeInput={useTime}
      showMonthDropdown
      showYearDropdown
      yearDropdownItemNumber={2}
      dropdownMode="select"
      dateFormat={useTime ? 'dd.MM.yyyy. HH:mm' : 'dd.MM.yyyy.'}
    />
  );
};

interface Props {
  name: string;
  control: Control<any, any>;
  useTime?: boolean;
  placeholder?: string;
  error?: boolean;
}
