import { DriverGroup } from './types';

export const resToDriverGroup = (res: any): DriverGroup => {
  return {
    id: res.id,
    name: res.name ?? '',
    bookingFee: res.bookingFee ?? null,
    vehicleImageFileUrl: res.vehicleImageFileUrl ?? '',
    vehicleImageFileId: res.vehicleImageFileId ?? '',
    onFleetTeamId: res.onFleetTeamId ?? '',
    showInApp: res.showInApp ?? false,
    description: res.description ?? '',
    isHelper: res.isHelper ?? false,
  };
};
