import { api } from 'services';
import { DataWithMeta, PaginationRequest } from 'helpers';
import { SignUpForm } from 'modules/SignUp/types';

import { resToUser, signUpFormToPostReq, userToPostReq, userToPutReq } from './transformations';
import { User, VerifiedEmail } from './types';
import { uploadImages } from 'services/images/helpers';

export const fetchUsers = async (pagination: PaginationRequest): Promise<DataWithMeta<User>> => {
  let url = `/users?page=${pagination.page}&pageSize=${pagination.pageSize}`;

  if (pagination.sortBy) url += `&sortBy=${pagination.sortBy}`;
  if (pagination.sortDirection) url += `&sortDirection=${pagination.sortDirection}`;
  if (pagination.search) url += `&search=${pagination.search}`;

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToUser),
  };
};

export const fetchUser = async (id: string): Promise<User> => {
  const resp = await api.get(`/users/${id}`);
  return resToUser(resp.data);
};

export const putUser = async (data: any): Promise<User> => {
  const resp = await api.put(`/users/${data.id}`, userToPutReq(data));
  return resToUser(resp.data);
};

export const postUser = async (data: any): Promise<User> => {
  const resp = await api.post(`/users`, userToPostReq(data));
  return resToUser(resp.data);
};

export const deleteUser = async (id: string): Promise<User> => {
  const resp = await api.delete(`/users/${id}`);
  return resToUser(resp.data);
};

export const verifyEmail = async (email: string): Promise<VerifiedEmail> => {
  const resp = await api.get(`/users/email`, { params: { email } });
  return resp.data;
};

export const registerDriver = async (form: SignUpForm): Promise<any> => {
  const images = await uploadImages({
    driversLicenseFile: form.driversLicenseFile,
    profilePictureFile: form.profilePictureFile,
    vehicleInsuranceFile: form.vehicleInsuranceFile,
    vehicleRegistrationFile: form.vehicleRegistrationFile,
  });

  const resp = await api.post(`/drivers`, signUpFormToPostReq({ ...form, ...images }));
  return resp.data;
};
