import { CellProps } from 'react-table';
import dayjs from 'dayjs';

import { TableColumn, Tooltip } from 'components';
import { shortenId } from 'helpers';
import { Order } from 'services/orders';

export const columns = (): TableColumn<Order>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    isSortable: true,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      return (
        <Tooltip
          popoverMessage="Copied!"
          tooltipMessage={rowData.id}
          onClick={() => navigator.clipboard.writeText(rowData.id)}
        >
          {shortenId(rowData.id)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'Date',
    accessor: 'startDateTime' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      const date = rowData.startDateTime ? dayjs(rowData.startDateTime).format('MM-DD-YYYY') : '';

      return <>{date}</>;
    },
  },
  {
    Header: 'Status',
    accessor: 'orderStatus' as any,
    isSortable: true,
  },
  {
    Header: 'Pickup Address',
    accessor: 'pickupAddress' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      return <>{rowData.pickupAddress}</>;
    },
  },
  {
    Header: 'Delivery Address',
    accessor: 'deliveryAddresses' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Order>) => {
      const rowData = row.original;
      const { deliveryAddresses } = rowData;

      if (deliveryAddresses.length === 0) {
        return <></>;
      }

      return (
        <Tooltip tooltipMessage={deliveryAddresses.join('<br/>')}>{`${deliveryAddresses[0]} ${
          deliveryAddresses.length > 1 ? `(+${deliveryAddresses.length - 1} more)` : ''
        }`}</Tooltip>
      );
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount' as any,
    isSortable: false,
    Cell: () => {
      return <>{'TODO'}</>;
    },
  },
];
