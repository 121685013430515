import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';

import { api } from '../api';
import { resToDriverGroup } from './transformations';
import { DriverGroup } from './types';

export const fetchDriverGroups = async (pagination: PaginationRequest): Promise<DataWithMeta<DriverGroup>> => {
  const url = paginationRequestToUrl('/driver_groups', pagination);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToDriverGroup),
  };
};

export const fetchDriverGroup = async (id: string): Promise<DriverGroup> => {
  const resp = await api.get(`/driver_groups/${id}`);
  return resToDriverGroup(resp.data);
};
