import clsx from 'clsx';
import { forwardRef } from 'react';

type InputProps = {
  error?: boolean;
} & JSX.IntrinsicElements['input'];

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, type = 'text', error = false, ...props },
  ref,
) {
  return (
    <input
      className={clsx(
        // eslint-disable-next-line max-len
        'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm',
        {
          'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500': !error,
          'border-red-300 placeholder-red-400 focus:ring-red-500 focus:border-red-500': error,
        },
        className,
      )}
      type={type}
      {...props}
      ref={ref}
    />
  );
});
