import { PageType } from 'helpers';
import { Order } from './types';

export const defaultOrder: Order = {
  id: PageType.Create,
  customerId: '',
  customerName: '',
  deliveryAddresses: [],
  deliveryWindow: null,
  driverGroupId: '',
  driverId: '',
  driverName: '',
  driverPayment: null,
  helperPayment: null,
  merchantId: '',
  promoCodeId: '',
  itemsDescription: '',
  loadHelperCount: null,
  unloadHelperCount: null,
  weight: null,
  tipOverride: null,
  startDateTime: '',
  totalMileage: null,
  mileagePrice: null,
  bookingPrice: null,
  weightPrice: null,
  firstHelperLoadPrice: null,
  firstHelperUnloadPrice: null,
  secondHelperLoadPrice: null,
  secondHelperUnloadPrice: null,
  expeditedFee: null,
  trailerPrice: null,
  loadHelperPrice: null,
  unloadHelperPrice: null,
  tipPrice: null,
  finalPrice: null,
  orderStatus: '',
  pickupAddress: '',
  pickupType: null,
  merchantOrderStatus: '',
  extraTips: null,
  paymentType: '',
  paymentStatus: null,
  paymentDate: '',
  paymentTransactionId: '',
  deviceType: '',
  stops: [],
};
