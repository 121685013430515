import { Checkbox, FormItem, Input } from 'components';
import { DatePicker } from 'components/DatePicker';
import { Select } from 'components/Select';
import { USAStates } from 'services';

import { useProfilePage } from '../../ProfilePageProvider';

export const PersonalInformation = () => {
  const { formState, register, control } = useProfilePage();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <FormItem label="First name" htmlFor="firstName" error={formState.errors.firstName?.message} required>
        <Input
          {...register('firstName')}
          id="firstName"
          type="text"
          placeholder="Enter first name"
          autoComplete="firstName"
          required
          error={!!formState.errors.firstName?.message}
        />
      </FormItem>

      <FormItem label="Last name" htmlFor="lastName" error={formState.errors.lastName?.message} required>
        <Input
          {...register('lastName')}
          id="lastName"
          type="text"
          placeholder="Enter last name"
          autoComplete="off"
          required
          error={!!formState.errors.lastName?.message}
        />
      </FormItem>

      <FormItem label="Email" htmlFor="email" error={formState.errors.email?.message} required>
        <Input
          {...register('email')}
          id="email"
          type="text"
          placeholder="Enter email"
          autoComplete="email"
          required
          error={!!formState.errors.email?.message}
        />
      </FormItem>

      {/* <FormItem label="Password" htmlFor="password" error={formState.errors.password?.message} required>
        <Input
          {...register('password')}
          id="password"
          type="password"
          placeholder="Enter password"
          autoComplete="off"
          required
          error={!!formState.errors.password?.message}
        />
      </FormItem> */}

      <FormItem label="Phone" htmlFor="phone" error={formState.errors.phoneNumber?.message} required>
        <Input
          {...register('phoneNumber')}
          id="phone"
          type="text"
          placeholder="Enter phone"
          autoComplete="off"
          required
          error={!!formState.errors.phoneNumber?.message}
        />
      </FormItem>

      <FormItem label="Date Of Birth" htmlFor="dateOfBirth" error={formState.errors.dateOfBirth?.message} required>
        <DatePicker
          control={control}
          name="dateOfBirth"
          placeholder="Enter date of birth"
          useTime={false}
          error={!!formState.errors.dateOfBirth?.message}
        />
      </FormItem>

      <FormItem label="Address 1" htmlFor="address1" error={formState.errors.address1?.message} required>
        <Input
          {...register('address1')}
          id="address1"
          type="text"
          placeholder="Enter address 1"
          autoComplete="off"
          required
          error={!!formState.errors.address1?.message}
        />
      </FormItem>

      <FormItem label="Address 2" htmlFor="address2" error={formState.errors.address2?.message}>
        <Input
          {...register('address2')}
          id="address2"
          type="text"
          placeholder="Enter address 2"
          autoComplete="off"
          required
          error={!!formState.errors.address2?.message}
        />
      </FormItem>

      <FormItem label="City" htmlFor="city" error={formState.errors.city?.message} required>
        <Input
          {...register('city')}
          id="city"
          type="text"
          placeholder="Enter city"
          autoComplete="off"
          required
          error={!!formState.errors.city?.message}
        />
      </FormItem>

      <FormItem label="State" htmlFor="state" error={formState.errors.state?.message} required>
        <Select
          name="driversLicenseState"
          options={Object.values(USAStates)}
          control={control}
          error={!!formState.errors.driversLicenseState?.message}
        />
      </FormItem>

      <FormItem label="ZIP Code" htmlFor="zip" error={formState.errors.zip?.message} required>
        <Input
          {...register('zip')}
          id="zip"
          type="text"
          placeholder="Enter address 2"
          autoComplete="off"
          required
          error={!!formState.errors.zip?.message}
        />
      </FormItem>

      <FormItem className="lg:pt-8">
        <Checkbox {...register('isHelper')} id="isHelper" label="Register as helper?" name="isHelper" />
      </FormItem>

      {/* <ImageUpload
        name="driversLicenseFile"
        control={control}
        label="Profiles Image"
        className="col-span-2"
        required
        error={formState.errors.driversLicenseFile?.url?.message}
      /> */}
    </div>
  );
};
