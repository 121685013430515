import { PageType } from 'helpers';

import { Image } from './types';

export const initialImage: Image = {
  id: PageType.Create,
  name: '',
  url: '',
  buffer: new ArrayBuffer(0),
};
