import { FormItem, Input } from 'components';
import { formatPrice } from 'helpers';

import { useOrderPage } from '../../OrderPageProvider';

export const OrderFees = () => {
  const { order } = useOrderPage();

  return (
    <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
      <FormItem label="Driver Payment">
        <Input type="text" disabled value={formatPrice(order.driverPayment ?? 0)} />
      </FormItem>

      <FormItem label="Helper Payment">
        <Input type="text" disabled value={formatPrice(order.helperPayment ?? 0)} />
      </FormItem>
    </div>
  );
};
