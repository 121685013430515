import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AuthCardLayout, Button } from 'components';

import { useSignUpPage } from './SignUpProvider';
import {
  SignUpStep1,
  SignUpStep2,
  SignUpStep3,
  SignUpStep4,
  SignUpStep5,
  SignUpStep6,
  SignUpStep7,
} from './components';

const Subtitle = (
  <p className="mt-2 text-center text-sm text-gray-600">
    Or{' '}
    <Link to="/sign-in" className="font-medium text-indigo-600 hover:text-indigo-500">
      sign in if you are already registered
    </Link>
  </p>
);

export const SignUp = () => {
  const { activeStep, formState, onSubmit, onPrevious } = useSignUpPage();
  const navigate = useNavigate();

  const ActiveStepContent = useCallback(() => {
    switch (activeStep) {
      case 1:
        return <SignUpStep1 />;
      case 2:
        return <SignUpStep2 />;
      case 3:
        return <SignUpStep3 />;
      case 4:
        return <SignUpStep4 />;
      case 5:
        return <SignUpStep5 />;
      case 6:
        return <SignUpStep6 />;
      case 7:
        return <SignUpStep7 />;
      default:
        return null;
    }
  }, [activeStep]);

  return (
    <>
      {activeStep !== 8 ? (
        <AuthCardLayout title="Create new driver account" subtitle={Subtitle} contentClassName="sm:max-w-4xl">
          <form className="space-y-6" onSubmit={onSubmit}>
            <ActiveStepContent />
            <div className="flex justify-between items-center">
              <small>
                Step <b>{activeStep}/7</b>
              </small>
              <div>
                {activeStep > 1 && (
                  <Button
                    type="button"
                    label="Previous"
                    disabled={formState.isSubmitting}
                    onClick={onPrevious}
                    className="mr-2"
                  />
                )}
                <Button type="button" label="Next" onClick={onSubmit} disabled={formState.isSubmitting} />
              </div>
            </div>
          </form>
        </AuthCardLayout>
      ) : (
        <AuthCardLayout contentClassName="sm:max-w-2xl pb-40">
          <div className="flex flex-col items-center justify-center text-center">
            <p className="text-gray-800 text-xl p-2">
              Your application has been received and will be reviewed shortly.
            </p>
            <p className="text-gray-500 text-md pb-2">
              Please watch for a background check link sent to your email which must be approved before your application
              is accepted.
            </p>
            <Button className="w-40 mt-4" label="Go to Sign In Page" onClick={() => navigate('/sign-in')} />
          </div>
        </AuthCardLayout>
      )}
    </>
  );
};
