import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Header, Divider, Tabs } from 'components';
import { OrderStop } from 'services/orders';

import { OrderDeliveryDetails, OrderFees, OrderItemSummary, OrderPickupDetails, OrderSummary } from './components';
import { useOrderPage } from './OrderPageProvider';

export const OrderPage = () => {
  const navigate = useNavigate();
  const { order } = useOrderPage();

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const selectedOrderStop: OrderStop = order.stops[currentIndex];

  const tabs = useMemo(() => {
    return order.stops.map((_, index) => ({
      name: `Stop ${index + 1}`,
      current: currentIndex === index,
    }));
  }, [order.stops, currentIndex]);

  const handleTabChange = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <>
      <Header title="Order" onGoBackPress={() => navigate(-1)} />

      <Container>
        <div className="overflow-hidden bg-white rounded-lg shadow">
          <div className="px-4 py-5 sm:p-6">
            <Divider title="Order Summary" />
            <OrderSummary />

            <Divider title="Order Fees" />
            <OrderFees />

            <Divider title="Order Item Summary" />
            <OrderItemSummary />

            <div className="ml-2">
              <Tabs tabs={tabs} onClick={handleTabChange} />
            </div>

            {tabs.length > 0 && (
              <div className="p-4 border border-gray-300 rounded-md">
                <Divider title="Order Pickup Details" />
                <OrderPickupDetails orderStop={selectedOrderStop} order={order} />

                <Divider title="Order Delivery Details" />
                <OrderDeliveryDetails orderStop={selectedOrderStop} order={order} />
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};
