import React from 'react';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase';

export const Button = ({ icon, label, ...props }: ButtonProps) => {
  return (
    <ButtonBase {...props}>
      {icon
        ? React.cloneElement(icon, {
            className: `${icon.props.className} -ml-0.5 mr-2 h-5 w-5 ${
              props.color === 'secondary' ? 'text-primary-500' : ''
            }`,
            'aria-hidden': true,
            ...icon.props,
          })
        : undefined}
      {label}
    </ButtonBase>
  );
};

type ButtonProps = {
  label?: string;
  icon?: JSX.Element;
} & ButtonBaseProps;
