import { resToAddress } from '../addresses';
import { Order, OrderStop } from './types';

export const resToOrder = (res: any): Order => {
  return {
    id: res.id ?? '',
    bookingPrice: res.bookingPrice ?? null,
    customerId: res.customerId ?? '',
    customerName: res.customerName ?? '',
    deliveryAddresses: res.deliveryAddresses ?? [],
    deliveryWindow: res.deliveryWindow ?? null,
    deviceType: res.deviceType ?? '',
    driverGroupId: res.driverGroupId ?? '',
    driverId: res.driverId ?? '',
    driverName: res.driverName ?? '',
    driverPayment: res.driverPayment ?? null,
    helperPayment: res.helperPayment ?? null,
    expeditedFee: res.expeditedFee ?? null,
    extraTips: res.extraTips ?? null,
    finalPrice: res.finalPrice ?? null,
    firstHelperLoadPrice: res.firstHelperLoadPrice ?? null,
    itemsDescription: res.itemsDescription ?? '',
    firstHelperUnloadPrice: res.firstHelperUnloadPrice ?? null,
    loadHelperCount: res.loadHelperCount ?? null,
    loadHelperPrice: res.loadHelperPrice ?? null,
    merchantId: res.merchantId ?? '',
    merchantOrderStatus: res.merchantOrderStatus ?? '',
    mileagePrice: res.mileagePrice ?? null,
    orderStatus: res.orderStatus ?? '',
    paymentDate: res.paymentDate ?? '',
    paymentStatus: res.paymentStatus ?? null,
    paymentTransactionId: res.paymentTransactionId ?? '',
    paymentType: res.paymentType ?? '',
    pickupAddress: res.pickupAddress ?? '',
    pickupType: res.pickupType ?? null,
    promoCodeId: res.promoCodeId ?? '',
    secondHelperLoadPrice: res.secondHelperLoadPrice ?? null,
    secondHelperUnloadPrice: res.secondHelperUnloadPrice ?? null,
    startDateTime: res.startDateTime ?? '',
    stops: (res.stops || []).map(resToOrderStop),
    tipOverride: res.tipOverride ?? null,
    tipPrice: res.tipPrice ?? null,
    totalMileage: res.totalMileage ?? null,
    trailerPrice: res.trailerPrice ?? null,
    unloadHelperCount: res.unloadHelperCount ?? null,
    unloadHelperPrice: res.unloadHelperPrice ?? null,
    weight: res.weight ?? null,
    weightPrice: res.weightPrice ?? null,
  };
};

export const resToOrderStop = (res: any): OrderStop => {
  return {
    id: res.id,
    contactName: res.contactName ?? '',
    contactPhone: res.contactPhone ?? '',
    deliveryDateTime: res.deliveryDateTime ?? '',
    driverDeliveryNote: res.driverDeliveryNote ?? '',
    fromAddress: res.fromAddress ? resToAddress(res.fromAddress) : null,
    itemHeight: res.itemHeight ?? null,
    itemLength: res.itemLength ?? null,
    itemQuantity: res.itemQuantity ?? null,
    itemsToBeDeliveredNote: res.itemsToBeDeliveredNote ?? '',
    itemWeight: res.itemWeight ?? null,
    itemWidth: res.itemWidth ?? null,
    orderId: res.orderId ?? '',
    pickupDateTime: res.pickupDateTime ?? '',
    pickupNote: res.pickupNote ?? '',
    senderPersonName: res.senderPersonName ?? '',
    senderPersonPhone: res.senderPersonPhone ?? '',
    shippingDistance: res.shippingDistance ?? null,
    shippingDistancePrice: res.shippingDistancePrice ?? null,
    stopNumber: res.stopNumber ?? null,
    toAddress: res.toAddress ? resToAddress(res.toAddress) : null,
  };
};
