import { DatePicker, Divider, FormItem, Input } from 'components';
import { useSignUpPage } from 'modules/SignUp/SignUpProvider';

export const SignUpStep1 = () => {
  const { formState, control, register } = useSignUpPage();

  return (
    <>
      <Divider title="Sign Up to Be a Driver" titlePosition="center" />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <FormItem label="First Name" htmlFor="firstName" error={formState.errors.firstName?.message} required>
          <Input
            {...register('firstName')}
            id="firstName"
            type="text"
            required
            placeholder="First Name"
            error={!!formState.errors.firstName?.message}
          />
        </FormItem>

        <FormItem label="Last Name" htmlFor="lastName" error={formState.errors.lastName?.message} required>
          <Input
            {...register('lastName')}
            id="lastName"
            type="text"
            required
            placeholder="Last Name"
            error={!!formState.errors.lastName?.message}
          />
        </FormItem>

        <FormItem label="E-mail Address" htmlFor="email" error={formState.errors.email?.message} required>
          <Input
            {...register('email')}
            id="email"
            type="email"
            required
            placeholder="E-mail"
            error={!!formState.errors.email?.message}
          />
        </FormItem>

        <FormItem label="Phone" htmlFor="phoneNumber" error={formState.errors.phoneNumber?.message} required>
          <Input
            {...register('phoneNumber')}
            id="phoneNumber"
            type="text"
            required
            placeholder="Phone"
            error={!!formState.errors.phoneNumber?.message}
          />
        </FormItem>

        <FormItem label="Password" htmlFor="password" error={formState.errors.password?.message} required>
          <Input
            {...register('password')}
            id="password"
            type="password"
            required
            placeholder="Password"
            error={!!formState.errors.password?.message}
          />
        </FormItem>

        <FormItem
          label="Confirm Password"
          htmlFor="confirmPassword"
          error={formState.errors.confirmPassword?.message}
          required
        >
          <Input
            {...register('confirmPassword')}
            id="confirmPassword"
            type="password"
            required
            placeholder="Confirm Password"
            error={!!formState.errors.confirmPassword?.message}
          />
        </FormItem>

        <FormItem label="Date of Birth" htmlFor="dateOfBirth" error={formState.errors.dateOfBirth?.message} required>
          <DatePicker control={control} name="dateOfBirth" error={!!formState.errors.dateOfBirth?.message} />
        </FormItem>
      </div>
    </>
  );
};
