import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

export const DashboardContext = React.createContext<unknown>({});

// This will be used in case we state shared inside the module
export const DashboardProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <DashboardContext.Provider value={providerValue}>{children}</DashboardContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
