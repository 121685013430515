import { FormItem, Input } from 'components';

import { useOrderPage } from '../../OrderPageProvider';

export const OrderItemSummary = () => {
  const { order } = useOrderPage();

  return (
    <div className="grid grid-cols-1 gap-4 py-5 lg:grid-cols-2">
      <FormItem label="Item description">
        <Input type="text" disabled value={order.itemsDescription} />
      </FormItem>
    </div>
  );
};
